<template>
    <div>
        <!-- <v-row v-if="formSearch.filter( e => e.value != '' && e.value != null).length > 0"> -->
        <v-row v-if="items">
            <v-col cols="12">
                <v-chip v-for="(item, index) of filters.filter( e =>  e.value != '' &&  e.value != null)" :key="index"
                class="ma-2"
                close
                @click:close="removeFilter(item.id)"
                >
                    {{item.label}}: <span class="ml-2" color="red"> {{ item.value }}</span>
                </v-chip>
            </v-col>
        </v-row>
        <v-row class="align-center">
            <v-col cols="16">
                <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-1"
                item-key="id"
                loading="true"
                >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip
                        :open-delay="400"
                        dense
                        content-class="tooltip-cool"
                        top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                small
                                @click="editModal(item)"
                                >
                                <v-icon color="success" x-small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                        <v-tooltip
                            :open-delay="400"
                            dense
                            content-class="tooltip-cool"
                            top>
                            <!-- :disabled="actionPermission('DEL')" -->
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="editModal(item)"
                                    >
                                    <v-icon color="red" x-small>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Eliminar</span>
                        </v-tooltip>
                </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'ClientTable',
    props: {
        items: {
            type: Array,
            required: true
        },
        filters: {
            type: Array
        }
    },
    data() {
        return {
            headers:[
                {text:"Nombre", value: "firstname"},
                {text:"Apellido", value: "lastname"},
                {text:"Telefono", value: "mobile_phone"},
                {text:"Email", value: "user.email"},
                {text:"DNI", value: "dni"},
                {text:"Acciones", value: "actions"},
            ]
        };
    },
    mounted() {

    },

    methods: {
        editModal(item){
            console.log('emit..');
            this.$emit('createEditClient', item)
        },
        removeFilter(value){
            this.$emit('removeFilter', value)
        },
    },
};
</script>

<style scoped>

</style>
