<template>
    <ClientsComponent />
  </template>

  <script>
  import ClientsComponent from "@/components/clients/ClientsComponent";

  export default {
    name: "ClientsView",

    components: {
        ClientsComponent,
    },
  };
  </script>
