<template>
    <div class="mx-3">
        <!-- Button Actions - data - filter - create -->
        <v-row class="align-center">
            <v-col cols="12">
                <v-bottom-navigation v-model="value" grow>
                    <v-btn @click="showComponent('data')">
                        <span>Datos</span>
                        <v-icon>mdi-view-list</v-icon>
                    </v-btn>
                    <v-btn @click="showComponent('filter')">
                        <span>Buscar</span>
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                    <v-btn @click="showComponent('create')">
                        <span>Crear</span>
                        <v-icon>mdi-note-plus</v-icon>
                    </v-btn>
                </v-bottom-navigation>
            </v-col>
        </v-row>
        <!-- Filter -->
        <v-card class=" mt-4 pa-3" v-if="statusComponent('filter')">
            <SaleFilter
            @SaleFilterAction="SaleFilterAction"
            :filters="filters"
            />
        </v-card>
        <!-- Form Create -->
        <v-card class="mt-4 pa-0" v-if="statusComponent('create')">
            <SaleCreateEdit
            @saleCreated="saleCreated"
            :action="action"
            :sale="sale"/>
        </v-card>

        <!-- Table -->
        <v-card class="mt-4 pa-2" v-if="statusComponent('data')">
            <SaleTable
            @createEditSale="createEditSale"
            :items="items"
            :filters="filters"
            @removeFilter="removeFilter"/>
        </v-card>
    </div>
</template>

<script>
import SaleCreateEdit from './SaleCreateEdit.vue';
import SaleFilter from './SaleFilter.vue';
import SaleTable from './SaleTable.vue';

export default {
    name: 'SalesComponent',
    components: { SaleCreateEdit, SaleFilter, SaleTable },
    data() {
        return {
            items:[],
            filters: [],
            sale: null,
            action:null,
            select: [0,1],
            listComponents:[
                { name: 'data', status: true },
                { name: 'filter', status: false },
                { name: 'create', status: false },
            ],
            value: null,
        };
    },
    computed: {
        statusComponent(){
            return (value) => {
                const component = this.listComponents.find( e => e.name === value)
                return component.status
            }
        }
    },
    watch: {
    },
    mounted() {
        this.getItems()
    },

    methods: {
        removeFilter(value){
            this.filters[value].hide = true
            this.filters[value].value = null
            this.getProfiles()
        },
        createEditSale(item){
            this.action = 'edit'
            this.sale = item
            this.showComponent('edit')
        },
        textFilter(){
            if(this.filters){
                let text = ''
                this.filters.forEach( e => {
                    if (e.value && !e.hide) {
                        text +=  e.name + '=' + e.value + '&'
                    }
                })
                return text
            } else {
                return ''
            }
        },
        getItems(){
            this.$http.get(process.env.VUE_APP_URI + `sales?${this.textFilter()}`)
            .then( res => {
                this.items = res.data.data
                this.showComponent('data')
            })
            .catch( err => console.log(err))
        },
        SaleFilterAction(value){
            this.filters = value
            this.getProfiles()
        },
        saleCreated(){
            this.getItems()
            this.statusComponent('data')
        },
        editModal(item){
            this.action = 'edit'
            this.profile = item
            this.showComponent('edit')
        },
        showComponent(value){
            if(value === 'create') {
                if (this.listComponents['create'] === true )  this.listComponents['create'] = false
                this.action = 'create'
            }
            if(value === 'edit') {
                this.action = 'edit'
                value = 'create'
            }

            this.listComponents.forEach( e => {
                (value === e.name) ? e.status = true : e.status = false
            })
        }
    },
};
</script>

<style scoped>

</style>
