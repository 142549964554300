<template>
    <div>
        <v-card-title>Buscar</v-card-title>
        <v-row class="ma-0">
        <v-col cols="12" md="4">
            <v-select
            v-model="filterSelected"
            :items="itemsFiltered"
            item-value="id"
            item-text="label"
            chips
            deletable-chips
            solo
            dense
            @change="changeFilter"
            placeholder="Agregar filtro"
            ></v-select>

            <v-chip v-for="(item, index) of formSearch.filter( e => !e.hide)" :key="index"
            class="ma-2"
            close
            @click:close="formSearch[item.id].hide = true; filterSelected = null; formSearch[item.id].value = null"
            >
                {{item.label}}
            </v-chip>
        </v-col>
        <v-col cols="12" md="8">
            <v-form>
                <v-card>
                <v-row class="ma-0 pa-1 align-center" no-gutters>
                    <v-col cols="12" class="px-1" md="4" v-if="!formSearch[0].hide">
                        <v-text-field
                            v-model="formSearch[0].value"
                            label="Nombre"
                            dense
                            filled
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="px-1" v-if="!formSearch[1].hide">
                          <v-select
                                :items="categories"
                                v-model="formSearch[1].value"
                                item-value="id"
                                item-text="description"
                                solo
                                dense
                                placeholder="Seleccione categoria"
                            ></v-select>
                    </v-col>
                     <v-col cols="12" md="4" class="px-1" v-if="!formSearch[2].hide">
                          <v-select
                                :items="brands"
                                v-model="formSearch[2].value"
                                item-value="id"
                                item-text="description"
                                solo
                                dense
                                placeholder="Seleccione marca"
                            ></v-select>
                    </v-col>
                    <v-col cols="12" md="4" class="px-1" v-if="!formSearch[3].hide">
                          <v-select
                                :items="sizes"
                                v-model="formSearch[3].value"
                                item-value="id"
                                item-text="description"
                                solo
                                dense
                                placeholder="Seleccione talle"
                            ></v-select>
                    </v-col>
                    <v-col cols="12" md="4" class="px-1" v-if="!formSearch[4].hide">
                          <v-select
                                :items="colors"
                                v-model="formSearch[4].value"
                                item-value="id"
                                item-text="description"
                                solo
                                dense
                                placeholder="Seleccione color"
                            ></v-select>
                    </v-col>
                    <v-col cols="12" md="4" class="px-1" v-if="!formSearch[5].hide">
                        <v-text-field
                            v-model="formSearch[5].value"
                            label="Mayor que"
                            dense
                            filled
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="px-1" v-if="!formSearch[6].hide">
                        <v-text-field
                            v-model="formSearch[6].value"
                            label="Menor que"
                            dense
                            filled
                            outlined
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row >
                    <v-col class="d-flex justify-end mr-3">
                        <v-btn small color="primary" :disabled="!formSearch.some( e => !e.hide)" @click="applyFilter">Aplicar filtro</v-btn>
                    </v-col>

                </v-row>
                </v-card>
            </v-form>
        </v-col>

        </v-row>
    </div>
</template>

<script>
export default {
    name: 'ProductFilter',
    props: {
        filters: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            categories: [],
            brands: [],
            sizes: [],
            colors: [],
            filterSelected: null,
            formSearch: [
                { id: 0, label: 'Nombre', name: 'name', value: null, hide: false, valueDescription: null },
                { id: 1, label: 'Categoria', name: 'product_category_id', value: null, hide: false, valueDescription: null },
                { id: 2, label: 'Marca', name: 'product_brand_id', value: null, hide: true, valueDescription: null },
                { id: 3, label: 'Talles', name: 'product_size_id', value: null, hide: true, valueDescription: null },
                { id: 3, label: 'Colores', name: 'product_color_id', value: null, hide: false, valueDescription: null },
                { id: 4, label: 'Mayor que', name: 'greater_than', value: null, hide: true, valueDescription: null },
                { id: 5, label: 'Menor que', name: 'small_than', value: null, hide: true, valueDescription: null },
            ],
            optionsFilter: [],

        };
    },
    computed: {
        itemsFiltered (){
            return this.formSearch.filter( e => e.hide)
        }
    },
    mounted() {
        this.getCategories()
        this.getBrands()
        this.getSizes()
        this.getColors()
        this.formSearch = (this.filters.length > 0) ?  this.filters : this. formSearch
        this.optionsFilter = this.formSearch.filter(e => e.id != 11)//.map( e => e.name)
    },
    methods: {
        getCategories(){
            this.$http.get(process.env.VUE_APP_URI + 'product_categories')
            .then( res => {
                this.categories = res.data.data
            })
            .catch( err => {
                console.log('err', err);
            })
        },
        getBrands(){
            this.$http.get(process.env.VUE_APP_URI + 'product_brands')
            .then( res => {
                this.brands = res.data.data
            })
            .catch( err => {
                console.log('err', err);
            })
        },
        getSizes(){
            this.$http.get(process.env.VUE_APP_URI + 'product_sizes')
            .then( res => {
                this.sizes = res.data.data
            })
            .catch( err => {
                console.log('err', err);
            })
        },
        getColors(){
            this.$http.get(process.env.VUE_APP_URI + 'product_colors')
            .then( res => {
                this.colors = res.data.data
            })
            .catch( err => {
                console.log('err', err);
            })
        },
        changeFilter(){
            this.formSearch[this.filterSelected].hide = false
        },
        applyFilter(){
            this.formSearch.forEach( e => {
                if(e.value === null || e.value === ''){
                    e.hide = true
                } else {
                    e.hide = false
                    e.valueDescription = e.value
                    if(e.name === 'product_category_id'){
                        e.valueDescription = this.categories.find( el => el.id === e.value).description
                    }
                    if(e.name === 'product_brand_id'){
                        e.valueDescription = this.brands.find( el => el.id === e.value).description
                    }
                    if(e.name === 'product_size_id'){
                        e.valueDescription = this.sizes.find( el => el.id === e.value).description
                    }

                }
            })
            this.$emit('productFilterAction', this.formSearch)
        }
    },
};
</script>

<style scoped>

</style>
