<template>
    <v-row class="ma-0">
        <v-col cols="12">
            <v-form ref="form">
                <v-card>
                    <v-card-title>
                        <span>{{ (this.action ==='create') ? 'Crear Producto' : 'Editar Producto'}}</span>
                        <!-- <span class="ml-4"><v-btn @click="postInFacebook">Publicar en Facebook</v-btn></span> -->
                    </v-card-title>

                    <v-row class="ma-0 pa-1 align-center" no-gutters v-if="formProduct.length > 0">
                        <v-col cols="12">
                            <v-row class="ma-0 pa-1 align-center">
                                 <v-col cols="12" md="3" class="px-1 pt-2 pb-0" v-if="!formProduct[0].hide">
                                    <v-text-field
                                        v-model="formProduct[0].value"
                                        label="Nombre"
                                        dense
                                        filled
                                        outlined
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" class="px-1 py-0" md="3" v-if="!formProduct[1].hide">
                                    <v-text-field
                                        v-model="formProduct[1].value"
                                        label="Descripcion"
                                        dense
                                        filled
                                        outlined
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" class="px-1 py-0" v-if="!formProduct[2].hide">
                                    <v-select
                                        :items="categories"
                                        v-model="formProduct[2].value"
                                        item-value="id"
                                        item-text="description"
                                        solo
                                        dense
                                        placeholder="Seleccione categoria"
                                        :rules="[rules.required]"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="3" class="px-1 py-0" v-if="!formProduct[3].hide">
                                    <v-select
                                        :items="brands"
                                        v-model="formProduct[3].value"
                                        item-value="id"
                                        item-text="description"
                                        solo
                                        dense
                                        placeholder="Seleccione marca"
                                        :rules="[rules.required]"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Component Images -->
                        <v-col cols="12" v-if="showImages">
                            <v-row class="ma-0 pa-1 align-center">
                                <v-col cols="12">
                                    <ProductImages
                                    @updateFiles="updateFiles"
                                    :action="action"
                                    :listImages="listImages"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="12" class="my-4">
                            <v-divider></v-divider>
                        </v-col>


                        <v-col cols="12" md="12" class="px-1" v-if="!formProduct[3].hide">
                            <v-card>
                                <v-form ref="formLoadSizes">
                                    <v-row>
                                        <v-col cols="12" md="3" class="py-0">
                                            <v-select
                                            :items="typeSize"
                                            v-model="typeSizeSelected"
                                            item-value="value"
                                            item-text="description"
                                            solo
                                            dense
                                            placeholder="Seleccione tipo Talle"
                                            :rules="[rules.required]"
                                            :disabled="action === 'edit'"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" md="3" class="py-0">
                                            <v-text-field
                                                v-model="cost"
                                                label="Precio Costo"
                                                dense
                                                filled
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" class="py-0">
                                            <v-text-field
                                                v-model="sell"
                                                label="Precio Venta"
                                                dense
                                                filled
                                                outlined
                                                :rules="[largerThanCost]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col class="py-0">
                                            <v-btn
                                            block
                                            small
                                            color="primary"
                                            :disabled="!typeSizeSelected"
                                            @click="selectSizes"
                                            >
                                            {{ (action === 'create' ? 'Agregar Precios a Talles' : 'Aplicar Prcios a Talles')}}
                                            </v-btn>
                                        </v-col>

                                        <!-- Table Sizes -->
                                        <v-col cols="12">
                                            <v-data-table
                                                :headers="headers"
                                                :items="items"
                                                class="elevation-1"
                                                item-key="id"
                                                hide-default-footer
                                            >
                                            <template v-slot:[`item.himage`]>
                                                <v-img
                                                lazy-src="https://picsum.photos/id/11/10/6"
                                                max-height="60"
                                                max-width="80"
                                                :src="imageBySize('1')"
                                                >
                                                </v-img>
                                            </template>

                                            <template v-slot:[`item.hcost`]="{ item }">
                                                <v-text-field
                                                    v-model="item.cost"
                                                    :disabled="item.disabled"
                                                    :rules="[rules.required]"
                                                ></v-text-field>
                                            </template>

                                             <template v-slot:[`item.hsell`]="{ item }">
                                               <v-text-field
                                                    v-model="item.sell"
                                                    :disabled="item.disabled"
                                                    :rules="[itemLargerThanCost(item.cost, item.sell), rules.required]"
                                                ></v-text-field>
                                            </template>

                                            <template v-slot:[`item.hcolor`]="{ item }">
                                                 <v-select
                                                :items="colors"
                                                v-model="item.color[0].product_color_id"
                                                item-value="id"
                                                item-text="description"
                                                :disabled="item.disabled"
                                                solo
                                                dense
                                                class="pt-4"
                                                ></v-select>
                                            </template>
                                            <template v-slot:[`item.actions`]="{ item, index }">
                                                <v-icon  v-if="item.disabled" @click="editItem(index)">
                                                    mdi-pencil
                                                </v-icon>
                                                  <v-icon v-else
                                                    :disabled="validatePrice(item.cost, item.sell)"
                                                   @click="confirmItem(index)">
                                                    mdi-content-save-alert
                                                </v-icon>
                                            </template>
                                            </v-data-table>

                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end mr-3">
                            <v-btn block small color="primary" :disabled="!formProduct.some( e => !e.hide)" @click="saveProduct">Guardar</v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-form>
        </v-col>

    </v-row>
</template>

<script>
import emptyImage from '@/assets/empty_image.png'
import ProductImages from '@/components/products/ProductImages.vue'

export default {
    name: 'ProductCreateEdit',
    components: { ProductImages},
    props: {
        action: {
            type: String,
            required: true
        },
        product: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            showImages: false,
            listImages: [],
            productImageSelected: 1,
            image:'',
            ruleImg: [ 'Formato no Aceptado'],
            params: {
                sendNow:true,
                loadingOk:false,
                loadingFile:false,
                recipientsSelectedQty:0,
                menu1:false,
                modal2:false,
                showPreview:false,
                sendToAll: false,
                labelSendNow:"Envío Inmediato",
                labelImg:"JPG o PNG. Tamaño  862 x 485 pixeles.",
            },
            imageFile: null,
            emptyImage: emptyImage,
            cost: null,
            sell: null,
            typeSize:[
                { id: 1, value: 'standard' , description: 'Standard' },
                { id: 2, value: 'letra',  description: 'Letra' },
                { id: 3, value: 'numerico', description: 'Numérico' },
                { id: 4, value: 'unico' , description: 'Unico' },
            ],
            sizeSelected: null,
            typeSizeSelected: 'standard',
            categories: [],
            brands: [],
            sizes: [],
            colors: [],
            exists: false,
            rules: {
                min: v => v.length >= 8 || 'Min 8 caracteres',
                required: v => !!v || 'Este dato es Requerido',
                requiredMultiple: v => v.length > 0 || 'Este dato es Requerido',
            },
            genders: [
                {
                    type: 'f',
                    label:'Femenino'
                },
                {
                    type: 'm',
                    label:'Masculino'
                },
                {
                    type: 'n',
                    label:'Sin especificar'
                }
            ],
            formProduct: [],
            headers:[
                { text:"Talle", value: "size"},
                // { text:"Color", value: "hcolor"},
                // { text:"Imagenes", value: "himage"},
                { text:"Precio Costo", value: "hcost"},
                { text:"Precio. Venta", value: "hsell"},
                { text:"Acciones", value: "actions"},
            ],
            items: []
        };
    },
    computed: {
        imageBySize(){
            return (id) => {
                let image = null
                if(this.listImages.length > 0){
                    if(this.listImages.some( e => e.file)){
                        image = this.listImages.find( el => el.order == 1).url
                    }
                }
                console.log(id)
                //const
                return (this.listImages.length === 0 || this.listImages.some(e => !e.file)) ? this.emptyImage : image
            }
        },
        ifExists(){
            if (this.exists === true){
                return "Email ya existe"
            } else {
                return true
            }
        },
        clientEmail() {
            return this.formProduct.map( item => item.value );
        }
    },
    watch: {
        action(newValue){
            if(newValue === 'create'){
                this.initial()
            }
        },
        clientEmail(newValue, oldValue){
            if(newValue[2] !=  oldValue[2]){
                this.exists = false
            }
        }
    },
    created(){
        // this.getCategories()
        // this.getBrands()
        // this.getSizes()
        // this.getColors()
    },
    mounted() {
        this.initial()
        this.getCategories()
        this.getBrands()
        this.getSizes()
        this.getColors()

    },


    methods: {
        postInFacebook(){
            const data = {
                title: this.formProduct[0].value,
                description: this.formProduct[1].value,
                category: 'Ropa y calzado de mujer',
                price: 2500
            }
            this.$http.post(process.env.VUE_APP_URI + 'facebook/marketplace', data)
            .then( res => {
                console.log('res', res.data.data);
            })
            .catch( err => {
                console.log('err', err);
            })
        },
        updateFiles(data){
            console.log('siii.............',data[0])

            // data.forEach( el => {
            //     console.log(el.image_id)
            // })
            // const updateData = data.map( e => {
            //     return {
            //         id: e.id,
            //         order: e.order,
            //         url: e.url,
            //         image_id: e.image_id
            //     }
            // })
            // console.log('updateFiles................', updateData);

            if(data.length > 0){
                // const imageDefault = data.find( e => e.order === 1)
                // console.log('imageDefault', imageDefault, 'image_id',imageDefault.url)
                // if (imageDefault) {
                // console.log('updateFiles----',data, 'imageDefault', imageDefault.image_id)


                // this.items.forEach(() => {
                //     //el.color[0].product_image_id = imageDefault.image_id;

                // });

                //let imageDefault = data.find( e => e.order === 1).image_id

                // this.items.forEach( el => {
                //     el.color[0].product_image_id = imageDefault
                // })
                //console.log('items', this.items);
                this.listImages = data
            }

        },
        updatePrices(){
            console.log('updatePrices....');
            this.items.forEach( e => {
                e.cost = this.cost
                e.sell = this.sell
            })
        },
        largerThanCost(){
            if(Number(this.cost) >= Number(this.sell)){
                return "debe ser mayor al precio de costo"
            } else {
                return true
            }
        },
        itemLargerThanCost(cost, sell){
            if(Number(cost) >= Number(sell)){
                return "debe ser mayor al precio de costo"
            } else {
                return true
            }
        },
        validatePrice(cost, sell){
            if(!cost || !sell) return true
            if(Number(cost) >= Number(sell)){
                return true
            } else {
                return false
            }
        },
        editItem(index){
            this.items[index].disabled = false
        },
        confirmItem(index){
            this.items[index].disabled = true
        },
        selectSizes(){
            if(this.action === 'create'){
                const idImageDefault = (this.listImages.length === 0 || this.listImages.some( el => !el.file) ) ? 1 : this.listImages.find( e => e.order === 1).image_id
                console.log('idImageDefault', idImageDefault)
                if(this.$refs.formLoadSizes.validate()){
                const sizes = this.sizes
                            .filter( e => e.type === this.typeSizeSelected)
                            .map( el => {
                                return {
                                    idSize: el.id.toString(),
                                    size: el.description,
                                    cost: this.cost,
                                    sell: this.sell,
                                    disabled: true,
                                    color: [{
                                                product_color_id: 1,
                                                product_image_id: idImageDefault
                                            }
                                    ]
                                }
                            })
            this.items = sizes
            }
            } else {
                this.updatePrices()
            }

        },
        getCategories(){
            this.$http.get(process.env.VUE_APP_URI + 'product_categories')
            .then( res => {
                this.categories = res.data.data
            })
            .catch( err => {
                console.log('err', err);
            })
        },
        getBrands(){
            this.$http.get(process.env.VUE_APP_URI + 'product_brands')
            .then( res => {
                this.brands = res.data.data
            })
            .catch( err => {
                console.log('err', err);
            })
        },
        getSizes(){
            this.$http.get(process.env.VUE_APP_URI + 'product_sizes')
            .then( res => {
                this.sizes = res.data.data
                if(this.action === 'edit'){
                    this.getProduct()
                }
            })
            .catch( err => {
                console.log('err', err);
            })
        },
        getProduct(){
            this.$http.get(process.env.VUE_APP_URI + `products/${this.product.id}`)
            .then( res => {
                this.item = res.data.data

                if(this.item.image){
                    this.emptyImage = process.env.VUE_APP_URI_IMAGE + this.item.image.name
                    this.productImageSelected = this.item.image.id
                }
                this.formProduct.forEach( e => {
                    if (e.name === 'name')  e.value = this.item.name
                    if (e.name === 'description') e.value = this.item.description
                    if (e.name === 'product_category_id') e.value = this.item.product_category_id
                    if (e.name === 'product_brand_id') e.value = this.item.product_brand_id
                })

                //   { text:"Talle", value: "size"},
                // // { text:"Color", value: "hcolor"},
                // // { text:"Imagenes", value: "himage"},
                // { text:"P. Costo", value: "hcost"},
                // { text:"P. Venta", value: "hsell"},
                // { text:"Acciones", value: "actions"},

                this.item.price.forEach( p => {
                    const item = {
                        id: p.id,
                        size: p.size.description,
                        cost: p.cost_price,
                        sell: p.selling_price,
                        disabled: true,
                        }
                    this.items.push(item)
                })


                // this.typeSizeSelected = this.item.price[0].size.type
                // if(this.sizes.length > 0){
                //     const sizes = this.sizes
                //             .filter( e => e.type === this.typeSizeSelected)
                //             .map( el => {
                //                 return {
                //                     id: this.item.price.find( element => element.size.description === el.description).id,
                //                     idSize: el.id.toString(),
                //                     size: el.description,
                //                     cost: this.item.price.find( element => element.size.description === el.description).cost_price,
                //                     sell: this.item.price.find( element => element.size.description === el.description).selling_price,
                //                     disabled: true,
                //                     // color: [
                //                     //     {
                //                     //         product_color_id: this.item.price.find( element => element.size.description === el.description).stock[0].product_color_id,
                //                     //         product_image_name: this.item.price.find( element => element.size.description === el.description).stock[0].image.name,
                //                     //         product_image_id: this.item.price.find( element => element.size.description === el.description).stock[0].image.id,
                //                     //     }
                //                     // ]
                //                 }
                //             })
                //             this.items = sizes
                // }



                if(this.item.images){
                    this.listImages = this.item.images.map( ( el, index) => {

                    const data = {
                        id: index + 1,
                        image_id: el.id,
                        order: el.order,
                        file: el.order,
                        name: el.name,
                        url: this.urlImage(el.name)
                    }
                    return data
                    });
                } else {
                    this.listImages = []
                }


                this.showImages = true
            })
            .catch( err => {
                console.log('err', err);
            })
        },
        urlImage(value){
            console.log('image...');
            if(value != 'empty_image.png'){
                return process.env.VUE_APP_URI_IMAGE + value
            } else {
                return null //imageDefault
            }
        },
        getColors(){
            this.$http.get(process.env.VUE_APP_URI + 'product_colors')
            .then( res => {
                this.colors = res.data.data
            })
            .catch( err => {
                console.log('err', err);
            })
        },
        initial(){
            this.formProduct = [
                { id: 0, label: 'Nombre', name: 'name', value: null, hide: false },
                { id: 1, label: 'Descripcion', name: 'description', value: null, hide: false },
                { id: 2, label: 'Categoria', name: 'product_category_id', value: null, hide: false, valueDescription: null },
                { id: 3, label: 'Marca', name: 'product_brand_id', value: null, hide: false, valueDescription: null },
            ]

            if(this.action === 'create'){
                this.showImages = true
            }
        },
        saveProduct(){
            this.exists = ""
            if(this.$refs.form.validate() && this.items.length > 0){
                const data = this.preparateData()

                if( this.action === 'create'){
                    this.$http.post(
                    process.env.VUE_APP_URI + 'products', data )
                    .then( res => {
                        console.log(res);
                        //this.saveImages(res.data.data, data)
                        this.$emit('productCreated');

                    })
                    .catch( err => {
                        if(err.response.status === 422){
                            const data = err.response.data
                            if(data.message === 'errorValidation'){
                                if(data.data.email) {
                                    this.exists = true
                                }
                            }
                        }
                    })
                } else {
                    console.log('data edit', data, 'product_id', this.product.id);
                    this.$http.put(
                    process.env.VUE_APP_URI + `products/${this.product.id}`, data )
                    .then( res => {
                        console.log('ok updated', res.data.data);
                        this.$emit('productCreated');
                    })
                    .catch( err => {
                        alert('Se produjo un error')
                        console.log(err)
                    })
                }

            }
        },
        saveImages(id){
            console.log('listImages', this.listImages);
            let formData = new FormData();
            formData.append('product_id', id);
            this.listImages.forEach((obj, index) => {

            // Agregar el ID y el nombre como campos de texto
            formData.append(`data[${index}][order]`, obj.order);
            });

            for (let i = 0; i < this.listImages.length; i++) {
                //formData.append(`order[]`, this.listImages[i].order);
                formData.append('images[]', this.listImages[i].file);
            }


             this.$http
                    .post(process.env.VUE_APP_URI + 'images', formData)
                    .then(res => {
                        this.params.loadingFile = false;
                        this.params.loadingOk = true;
                        //this.image = response.data.Location;
                        console.log('res', res);
                        // this.emptyImage = 'http://localhost/storage/' + res.data.data.name
                        // this.productImageSelected = res.data.data.id
                    })
                    .catch(error => {
                        console.log({ error });
                    });
        },
        preparateData(){
            // this.items.forEach( el => {
            //         el.color[0].product_image_id = this.productImageSelected
            //         el.color[0].product_image_name = this.emptyImage
            // })
            const data = this.formProduct
                .map( el => {
                    const obj = {};
                    obj[el.name] = el.value
                    return obj
                })
                .reduce((acc, cur) => {
                    const [key, value] = Object.entries(cur)[0];
                    acc[key] = value;
                    return acc;
                }, {})
                data.prices = this.items
                data.product_image_id = this.productImageSelected
                data.listImages = this.listImages.map( el => {
                    return {
                        file: el.file,
                        id: el.id,
                        order: el.order,
                        image_id: el.image_id
                    }
                })
            return data
        },

    },
};
</script>

<style scoped>

</style>
