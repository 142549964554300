<template>
    <v-row class="ma-0">
        <v-col cols="12">
            <v-form ref="form">
                <v-card>
                    <v-card-title>{{ (this.action ==='create') ? 'Nueva Venta' : 'Editar Venta'}}</v-card-title>
                    <v-row class="ma-0 pa-1 align-center" no-gutters v-if="formSale.length > 0">
                        <v-col cols="12" md="4" class="px-1" v-if="!formSale[0].hide">
                            <!-- @change="changeProvider" -->
                            <v-select
                                :items="clients"
                                item-value="user_id"
                                item-text="name"
                                :disabled="action === 'edit'"
                                solo
                                dense

                                v-model="formSale[0].value"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1" v-if="!formSale[2].hide">
                            <v-select
                                :items="payment_methods"
                                item-value="id"
                                item-text="description"
                                :disabled="action === 'edit'"
                                solo
                                dense
                                @change="changeMethod"
                                v-model="formSale[2].value"
                            ></v-select>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="12" md="4" class="px-1" v-if="!formSale[1].hide">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="formSale[1].value"
                                    label="Fecha"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[rules.required]"
                                    :disabled="action === 'edit'"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="formSale[1].value"
                                    no-title
                                    scrollable
                                    :disabled="action === 'edit'"
                                    >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false">
                                    Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(date)">
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                     <v-row >
                        <v-col cols="12" md="3" class="d-flex justify-start">
                            <v-btn block small color="primary" :disabled="formSale[0].value == 0 || action === 'edit'" @click="openDialog">Agregar Producto</v-btn>
                        </v-col>
                    </v-row>

                    <!-- Datatable Sales Detail -->
                    <v-row>
                        <v-col cols="12">
                            <v-data-table
                                :headers="headers"
                                :items="items"
                                class="elevation-1"
                                item-key="id"
                                hide-default-footer
                            >
                            <template v-slot:[`item.image`]=" { item } ">
                                <v-img
                                max-height="60"
                                max-width="80"
                                :src='item.image'
                                >
                                </v-img>
                            </template>
                            <!-- :disabled="item.disabled" -->
                            <template v-slot:[`item.cost_price`]="{ item }">
                                <v-text-field
                                    v-model="item.cost_price"
                                    :rules="[rules.required, rules.numberValid]"
                                    :disabled="true"
                                ></v-text-field>
                            </template>

                            <template v-slot:[`item.selling_price`]="{ item }">
                                <v-text-field
                                    v-model="item.selling_price"
                                    :rules="[rules.required, rules.numberValid]"
                                    :disabled="true"
                                ></v-text-field>
                            </template>

                            <template v-slot:[`item.quantity`]="{ item }">
                                <v-text-field
                                    v-model="item.quantity"
                                    :disabled="action ==='edit'"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </template>

                            <template v-slot:[`item.subtotal`]="{ item }">
                                <v-text-field
                                    v-model="item.subtotal"
                                    :disabled="true"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-tooltip
                                    :open-delay="400"
                                    dense
                                    content-class="tooltip-cool"
                                    top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-if="saleStatus === '1'"
                                            small
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="editProduct(item)"
                                            >
                                            <v-icon color="blue" x-small>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Edit Product</span>
                                </v-tooltip>
                                <v-tooltip
                                    :open-delay="400"
                                    dense
                                    content-class="tooltip-cool"
                                    top>

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-if="saleStatus === '1'"
                                            small
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="deleteItem(item)"
                                            >
                                            <v-icon color="red" x-small>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Eliminar</span>
                                </v-tooltip>
                            </template>
                            </v-data-table>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex justify-start ml-3">
                            <v-btn class="mr-6" small color="secondary" :disabled="items.length == 0 || SalePaid" @click="openDialog('addPayment')">Agregar Pago</v-btn>
                            <v-btn small color="secondary" @click="addPaymentTotal" :disabled="items.length === 0 || (items.length > 0 && payments.length > 0 )">Pago Total</v-btn>
                        </v-col>
                    </v-row>

                    <!-- Datatable Sales Payments -->
                    <v-row>
                        <v-col cols="12">
                            <v-data-table
                                :headers="headersPayments"
                                :items="payments"
                                class="elevation-1"
                                item-key="id"
                                hide-default-footer
                            >
                            <template v-slot:[`item.paymentMethod`]="{ item }">
                                {{ paymentDescription (item.paymentMethod) }}
                            </template>

                            <template v-slot:[`item.actions`]="{ item, index }">
                                <!-- <v-tooltip
                                    :open-delay="400"
                                    dense
                                    content-class="tooltip-cool"
                                    top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-if="saleStatus === '1'"
                                            small
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="editProduct(item)"
                                            >
                                            <v-icon color="blue" x-small>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Edit Product</span>
                                </v-tooltip> -->
                                <v-tooltip
                                    :open-delay="400"
                                    dense
                                    content-class="tooltip-cool"
                                    top>
                                    <!-- :disabled="actionPermission('DEL')" -->
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-if="saleStatus === '1' || !item.notDelete"
                                            small
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="deleteItemPay(index)"
                                            >
                                            <v-icon color="red" x-small>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Eliminar</span>
                                </v-tooltip>
                            </template>
                            </v-data-table>
                        </v-col>
                    </v-row>

                    <v-row >
                        <v-col cols="12" class="d-flex justify-center mr-3">
                            <span class="mx-5">Total Venta: $ {{ saleTotal }}</span>
                            <span class="mx-5">Total Pagos:  $ {{ paymentTotal }}</span>
                            <span class="mx-5">Saldo pendiente: $ {{ missingBalance }}</span>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center mr-3">
                              <v-btn
                                block
                                small
                                color="success"
                                :loading="savingSale"
                                :disabled="(items.length === 0 || payments.length === 0) || (action === 'edit' && notAddPayment)"
                                @click="saveSale()">Guardar Venta
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-form>
        </v-col>

        <!-- Modal search product -->
        <v-dialog
        v-model="dialog"
        persistent
        width="1000"
        >
        <v-card fuild class="pa-0 ma-0">
            <v-card-title v-if="clients.find( (e, index) => index === formSale[0].value)">
                Seleccione un producto de {{ clients.find( (e, index) => index === formSale[0].value).name }}
            </v-card-title>
            <v-row class="py-0 px-2 ma-0">
                <v-col cols="12" md="1">
                    <v-text-field
                        v-model="formSearch.productId"
                        label="Id"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        v-model="formSearch.productName"
                        label="Nombre de producto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        :items="productBrands"
                        v-model="formSearch.productBrand"
                        item-value="id"
                        item-text="description"
                        label="Marca"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-btn block color="primary" dark @click="searchProduct">Buscar Producto</v-btn>
                </v-col>
            </v-row>

            <v-row class="pa-0 ma-0">
                <v-col cols="12">
                    <v-data-table
                        :headers="productFoundheaders"
                        :items="productsFound"
                        item-key="index"
                     >
                        <template v-slot:[`item.image`]=" { item } ">
                                <v-img
                                class="ma-2"
                                max-height="60"
                                max-width="80"
                                :src='item.image'
                                >
                                </v-img>
                        </template>
                        <!-- v-model="item.color" -->
                        <!-- <template v-slot:[`item.sizes`]="{ item }">
                            <v-select
                            :items="sizes(item)"
                            v-model="item.sizeSelected"
                            item-value="id"
                            item-text="description"
                            solo
                            dense
                            class="pt-4"
                            ></v-select>
                        </template> -->
                        <!-- :disabled="!item.sizeSelected" -->
                        <template v-slot:[`item.select`] = "{ item }">
                            <v-btn
                                color="success"

                                @click="actionSelect(item, item.sizeSelected)"
                            >
                                Seleccionar
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" >
                <v-col class="d-flex justify-start ml-3">
                    <v-btn block small color="primary" :disabled="formSale[0].value == 0" @click="dialog = false">Cerrar</v-btn>
                </v-col>
            </v-row>
        </v-card>
        </v-dialog>

          <!-- Modal Agregar Pago -->
        <v-dialog
        v-model="dialogPay"
        persistent
        width="900"
        >
        <v-card fuild class="pa-0 ma-0">
            <v-card-title>
                Agregar Pago
            </v-card-title>
            <v-form ref="formAddPayment">
                <v-row class="py-0 px-2 ma-0">
                    <v-col cols="2">
                        <v-text-field
                            v-model="paymentAmount"
                            label="Monto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-select
                            :items="payment_methods"
                            v-model="paymentMethod"
                            item-value="id"
                            item-text="description"
                            label="Marca"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="3" class="px-1">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="paymentDate"
                                label="Fecha"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="[rules.required]"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="paymentDate"
                                no-title
                                scrollable
                                >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                                Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="$refs.menu.save(date)">
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                        </v-col>
                    <v-col cols="3">
                        <v-btn color="primary" dark @click="addPayment">Agregar</v-btn>
                    </v-col>
                </v-row>
            </v-form>

            <!-- <v-row class="pa-0 ma-0">
                <v-col cols="12">
                    <v-data-table
                        :headers="productFoundheaders"
                        :items="productsFound"
                     >
                        <template v-slot:[`item.sizes`]="{ item }">
                            <v-select
                            :items="sizes(item)"
                            v-model="item.sizeSelected"
                            item-value="id"
                            item-text="description"
                            solo
                            dense
                            class="pt-4"
                            ></v-select>
                        </template>

                        <template v-slot:[`item.select`] = "{ item }">
                            <v-btn
                                color="success"
                                :disabled="!item.sizeSelected"
                                @click="actionSelect(item, item.sizeSelected)"
                            >
                                Seleccionar
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row> -->
            <v-row class="pa-0 ma-0" >
                <v-col class="d-flex justify-start ml-3">
                    <v-btn small color="primary" @click="dialogPay = false">Cerrar</v-btn>
                </v-col>
            </v-row>
        </v-card>
        </v-dialog>

        <template v-if="actionSave">
            <ModalSave
                @close="actionSave = false"
                @save="save('addStock')"
                title="Esta agregando stock de productos"
                message="Esta operacion no puede volverse atras. Esta segur@ que desea guardar?"
            />
        </template>

        <template v-if="actionEditProduct">
            <!-- @productUpdated="save('addStock')" -->
            <ProductCreateEditModal
                @close="updateDataListItems"
                :product="productEdit"
                :action="actionProduct"
            />
        </template>
    </v-row>
</template>

<script>
import ModalSave from '@/components/commons/ModalSave.vue'
import ProductCreateEditModal from '@/components/products/ProductsCreateEditModal.vue'

export default {
    name: 'SaleCreateEdit',
    components: { ModalSave, ProductCreateEditModal},
    props:{
        action: {
            type: String,
            required: true
        },
        sale: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            savingSale: false,
            paymentDate: null,
            paymentAmount: null,
            paymentMethod: 3,
            payments: [],
            payment_methods: [],
            emptyImage: null,
            productEdit: null,
            actionProduct: 'edit',
            actionEditProduct: false,
            actionSave: false,
            saleStatus: '1',
            saleTotal: 0,
            formSearch: {
                productId: null,
                productName: null,
                productBrand: null,
            },
            productsFound: [],
            productBrands: [],
            productBrand: 0,
            dialog: false,
            dialogPay: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            modal: false,

            exists: false,
            rules: {
                min: v => v.length >= 8 || 'Min 8 caracteres',
                required: v => !!v || 'Este dato es Requerido',
                numberValid: v => (v > 0) || 'Debe ser mayor a cero',
                requiredMultiple: v => v.length > 0 || 'Este dato es Requerido',
            },
            clients: [
                {id: 1,
                 name: 'seleccione'}
            ],
            departments: [],
            formSale: [
                { id: 0, label: 'Cliente', name: 'provider_id', value: 0, hide: false },
                { id: 1, label: 'Fecha', name: 'sale_date', value: null, hide: false },
                { id: 2, label: 'Metodo de Pago', name: 'payment_method', value: null, hide: false },
            ],
            headers:[
                { text:"Imagen", value: "image"},
                { text:"Stock Id", value: "product_stock_id"},
                { text:"Producto", value: "name"},
                { text:"Talle", value: "size"},
                { text:"Color", value: "color"},
                { text:"P. Costo", value: "cost_price"},
                { text:"P. Venta", value: "selling_price"},
                { text:"Cantidad", value: "quantity"},
                { text:"Subtotal", value: "subtotal"},
                { text:"Acciones", value: "actions"},
            ],
            headersPayments: [
                { text:'Monto', value:'amount'},
                { text:'M. de Pago', value:'paymentMethod'},
                { text:'Fecha', value:'paymentDate'},
                { text:"Acciones", value: "actions"},
            ],
            productFoundheaders: [
                { text:"Imagen", value: "image"},
                { text: "Product Id", value: "id" },
                { text: "Nombre", value: "name" },
                { text: "Categoria", value: "category" },
                { text: "Marca", value: "brand" },
                { text: "Color", value: "color.description" },
                { text: "Talle", value: "size.description" },
                { text: "Stock", value: "stock" },
                { text: "Acction", value: "select" },
            ],
            items: [],
            saleId: null
        };
    },
    computed: {
        paymentTotal(){
            if(this.payments.length > 0){
                const payments = this.payments.reduce( (acc, el) => { return acc + Number(el.amount) }, 0 )
                return payments
            } else {
                return 0
            }

        },
        missingBalance(){
            if(this.payments.length > 0 && this.items.length > 0){
                const payments = this.payments.reduce( (acc, el) => { return acc + Number(el.amount) }, 0 )

                const total = this.items.reduce( (acc, e) => {
                    return acc + e.subtotal
                }, 0)

                if(total > payments){
                    return total - payments
                } else {
                    return 0
                }


            } else {
                return null
            }
        },
        SalePaid(){
              const amount = this.items.reduce( (acc, e) => {
                    return acc + e.subtotal
                }, 0)

                const totalPayments = this.payments.reduce( (acc, e) => {
                    return acc + Number(e.amount)
                }, 0)
            return amount > totalPayments ? false : true
        },
        paymentDescription(){
            return (id) => {
                return this.payment_methods.find( e => e.id === id).description
            }
        },
        ifExists(){
            if (this.exists === true){
                return "Email ya existe"
            } else {
                return true
            }
        },
        clientEmail() {
            return this.formSale.map( item => item.value );
        },
        notAddPayment(){
            return !this.payments.some( item => item.notDelete === false)
        }
    },
    watch: {
        action(newValue){
            if(newValue === 'create'){
                this.initial()
            }
        },
        clientEmail(newValue, oldValue){
            if(newValue[2] !=  oldValue[2]){
                this.exists = false
            }
        },
        items: {
            handler: function (newValue) {
                newValue.forEach( e => {
                    e.subtotal = e.selling_price * e.quantity
                })

                this.saleTotal = newValue.reduce( (acc, e) => {
                    return acc + e.subtotal
                }, 0)
            },
            deep: true
        }
    },
    mounted() {
        this.initial()
        this.getClients()
        if(this.action === 'edit'){
            this.saleId = this.sale.id
            this.getSale(this.sale.id);
        }
    },

    methods: {
        initial(){
            this.getPayMethods()
            this.formSale = [
                { id: 0, label: 'Cliente', name: 'provider_id', value: 0, hide: false },
                { id: 1, label: 'Fecha', name: 'sale_date', value: null, hide: false },
                { id: 2, label: 'Metodo de Pago', name: 'payment_method', value: null, hide: false },
            ]
        },
        getPayMethods(){
            this.$http.get(process.env.VUE_APP_URI + 'payment_methods')
            .then(res => {
                res.data.data.unshift( { id:0, description: 'Seleccione metodo de pago'})
                this.payment_methods = [...res.data.data] //.push( { id:0, description: 'Seleccione metodo de pago'}, [...res.data.data])
            })
            .catch( err => {
                console.log(err);
            })
        },
        updateDataListItems(product = null, data = null,  name = null){
            if(data){
                if(this.items.length > 0){
                    this.items.forEach( el => {
                        if (el.product_id === product.id) {
                            el.name = name
                            el.cost_price = data.find( e => e.idSize == el.idSize.toString()).cost
                            el.selling_price = data.find( e => e.idSize == el.idSize.toString()).sell
                            el.image = data.find( e => e.idSize == el.idSize.toString()).color[0]['product_image_name']
                        }
                    })
                }
            }
            this.actionEditProduct = false
        },
        editProduct(item){
            //console.log('...item', item);
            this.productEdit = {
                id: item.product_id,
                itemStockSelected: item.product_stock_id,
                name: item. name

            }
            this.actionEditProduct = true;
        },
        deleteItem(item){
            this.items = this.items.filter ( e => e.product_stock_id != item.product_stock_id)
        },
        deleteItemPay(index){
            this.payments = this.payments.filter ( (e, i) => i != index)
        },
        getSale(saleId){
            this.$http.get(
                process.env.VUE_APP_URI + `sales/${saleId}`)
                .then( res => {
                    this.saleStatus = res.data.data.status
                    this.formSale[0].value = res.data.data.user_id
                    this.formSale[1].value = res.data.data.sale_date
                    this.formSale[2].value = res.data.data.payment_type_id

                    res.data.data.sale_detail.forEach( e => {
                        e.image = process.env.VUE_APP_URI_IMAGE + e.image
                    });

                    this.items = res.data.data.sale_detail.map( item => {
                        return {
                            image: process.env.VUE_APP_URI_IMAGE + item.product_stock.image.name,
                            product_stock_id: item.product_stock.id,
                            name: item.product_stock.product_price.product.name,
                            size: item.product_stock.product_price.size.description,
                            color: item.product_stock.product_color_id,
                            cost_price: item.product_stock.product_price.cost_price,
                            selling_price: item.product_stock.product_price.selling_price,
                            quantity: item.quantity,
                            subtotal: item.subtotal
                        }

                    })

                    this.payments = res.data.data.sale_payment.map( payment => {
                        return {
                            amount: payment.amount,
                            paymentMethod: payment.payment_type_id,
                            paymentDate: payment.payment_date,
                            notDelete: true
                        }
                    })
                })
                .catch( err => console.log(err))
        },
        actionSelect(item){
            console.log('item..', item);
            //const sizeExists = this.items.some( e => e.idSize === itemSelected && e.product_id === item.id)

            //if(!sizeExists) {
              //  const data = item.price.find( e => e.product_size_id === itemSelected);

            const product = {
                product_id: item.id,
                product_stock_id:  item.product_stock_id,
                idSize: item.size,
                image:  process.env.VUE_APP_URI_IMAGE + item.image.name,
                name: item.name,
                size: item.size.description,
                color: item.color.description,
                cost_price: item.cost_price,
                selling_price: item.selling_price,
                quantity: 1,
                subtotal: null
            }
            this.items.push(product);
            // } else {
            //     alert('el talle ya existe')
            // }
            this.dialog = false
        },
        sizes(item){

            const prices = item.price.filter(el => el.stock.length > 0)
            const sizes = prices.map( it => {
                const data = it.stock.filter( st => st.stock != null);
                //console.log('data size', it)
                if(data) {
                    return {
                           id: it.product_size_id,
                            description: it.size.description,
                            product_stock_id: it.id
                    }
                } //return it.product_size_id
                //return 'hola'//return it.size
            })
        return sizes;
        },
        searchProduct(){
            this.productsFound = []
            let filter = '?'
            filter += (this.formSearch.productId) ? `id=${this.formSearch.productId}&` : ''
            filter += (this.formSearch.productName) ? `name=${this.formSearch.productName}&` : ''
            filter += (this.formSearch.productBrand) ? `product_brand_id=${this.formSearch.productBrand}` : ''
            this.$http.get(
                process.env.VUE_APP_URI + 'search_product'+ filter)
                .then( res => {
                    res.data.data.data.forEach( product => {
                        const prices = product.price.filter(el => el.stock.length > 0)
                        prices.forEach( price => {
                            price.stock
                                .filter( st => st.stock != null)
                                .forEach( size => {
                                    if(size.stock != null && size.stock > 0){
                                        this.productsFound.push({
                                            id: product.id,
                                            image:  process.env.VUE_APP_URI_IMAGE + size.image.name,
                                            name: product.name,
                                            category: product.category.description,
                                            brand: product.brand.description,
                                            color: size.product_color,
                                            size: price.size,
                                            product_stock_id: size.id,
                                            cost_price: price.cost_price,
                                            selling_price: price.selling_price,
                                            quantity: 1,
                                            subtotal: null,
                                            stock: size.stock
                                        })
                                    }
                                });
                        })

                    })

                })
                .catch( err => console.log(err))
        },
        addPaymentTotal(){
            const saleTotal = this.items.reduce( (acc, e) => {
                    return acc + e.subtotal
                }, 0)

            const item = {
                amount:  saleTotal,
                paymentMethod: this.formSale[2].value,
                paymentDate: this.formSale[1].value
            }

            this.payments.push(item)
            this.paymentAmount = null
            this.paymentMethod = null
            this.paymentDate = null
        },
        addPayment(){
            if(this.$refs.formAddPayment.validate()){
                const item = {
                    amount:  this.paymentAmount,
                    paymentMethod: this.paymentMethod,
                    paymentDate: this.paymentDate,
                    notDelete: false,
                }

                this.payments.push(item)
                this.paymentAmount = null
                this.paymentMethod = null
                this.paymentDate = null
                this.dialogPay = false;
            }
        },
        openDialog(name = null){
           if(name === 'addPayment'){
                const amount = this.items.reduce( (acc, e) => {
                    return acc + e.subtotal
                }, 0)

                const totalPayments = this.payments.reduce( (acc, e) => {
                    return acc + Number(e.amount)
                }, 0)

                console.log('totalPayments', totalPayments);

                this.paymentAmount = amount - totalPayments
                this.paymentMethod = this.formSale[2].value
                this.dialogPay = true
           } else {
                this.formSearch = {
                    productName: null,
                    productBrand: null,
                },
                this.productsFound = [],
                this.productBrands = [],
                this.getBrands()
                this.dialog = true;
           }

        },
        getBrands(){
            //const provider_id = this.clients.find( (e, index) => index === this.formSale[0].value).id
            this.$http.get(
                process.env.VUE_APP_URI + 'product_brands')
                .then( res => {
                    this.productBrands = res.data.data
                    this.productBrands.unshift({id: 0, description: 'Todas'})
                    this.formSearch.productBrand = 0
                })
                .catch( err => console.log(err))
        },
        saveSale(){
            this.actionSave = true;
        },
        save(action){
            if(this.$refs.form.validate()){

                const data = this.preparateData(action)
                if( this.action === 'create'){
                    this.savingSale = true
                    console.log('create sales');
                    this.$http
                    .post(process.env.VUE_APP_URI + 'sales', data )
                    .then( () => {
                        console.log('sales sales');
                        this.savingSale = false
                        this.$emit('saleCreated');
                    })
                    .catch( err => {
                        if(err.response.status === 422){
                            const data = err.response.data
                            if(data.message === 'errorValidation'){
                                if(data.data.email) {
                                    this.exists = true
                                }
                            }
                        }
                    })
                } else {
                    console.log('payments save', data);
                    this.$http
                        .put( process.env.VUE_APP_URI + `sales/${this.saleId}`, data )
                        .then( () => {
                            this.$emit('saleCreated');
                        })
                        .catch( err => console.log(err))
                }

            }
        },
        changeMethod(){
            this.paymentMethod = this.formSale[2].value
        },
        getClients(){
            this.$http.get(process.env.VUE_APP_URI + 'clients')
                .then( res => {
                    this.clients = res.data.data
                    this.clients.unshift( { user_id:0, name:'Selecciona Cliente'} )
                })
        },
        getDepartments(value){
            this.$http.get(process.env.VUE_APP_URI + `departments/${value}`)
                .then( res => {
                    this.departments = res.data.data
                })
        },
        preparateData(action){

            if(this.action ==='create'){
                const  data = {
                payments: this.payments,
                user_id: this.formSale[0].value,
                sale_date: this.formSale[1].value,
                payment_type_id: this.formSale[2].value,
                sale_details: this.items.map( e => {
                        return {
                            product_id: e.product_id,
                            product_stock_id: e.product_stock_id,
                            selling_price: e.selling_price,
                            quantity: e.quantity
                        }
                }),
                status: (action === 'draft') ? '1' : '2',

                //payment_purchases: null

                }
                return data
            } else {
                const  data = {
                    payments: this.payments.filter( item => item.notDelete === false)
                }
                return data
            }


        }
    },
};
</script>

<style scoped>

</style>
