import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import ClientsView from "../views/ClientsView.vue";
import ProductsView from "../views/ProductsView.vue";
// import OrdersView from "../views/OrdersView.vue";
import ShoppingsView from "../views/ShoppingsView.vue";
import SalesView from "../views/SalesView.vue";
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/clients",
    name: "clients",
    component: ClientsView,
  },
  {
    path: "/sales",
    name: "sales",
    component: SalesView,
  },
  {
    path: "/products",
    name: "products",
    component: ProductsView,
  },
  {
    path: "/shoppings",
    name: "shoppings",
    component: ShoppingsView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
    if(store.state.authentication.user) {
        store.dispatch('updateTitle', to.name)
        next()
    } else {
        if(to.path === '/login') {
            next()

        } else {
            router.push('/login')
        }

    }

})

export default router;
