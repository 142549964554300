<template>
    <ProductsComponent />
</template>

<script>
  import ProductsComponent from "@/components/products/ProductsComponent"

  export default {
    name: "ProductsView",

    components: {
        ProductsComponent,
    },
  };
  </script>
