<template>
  <v-row class="justify-center mx-2" >
    <v-col cols="12" md="4" class="my-2">
        <v-btn  class="my-4" block  v-for="(item, index) in menu" :key="index" @click="navigate(item.path)">
        {{ item.name }}
        </v-btn>
    </v-col>
  </v-row>
</template>
<script>
  export default {
    data () {
        return {
            menu: [
                // {name: "Inicio", path: 'home'},
                {name: "Clientes", path: 'clients'},
                {name: "Productos", path: 'products'},
                {name: "Ventas", path: 'sales'},
                {name: "Compras", path: 'shoppings'},
            ]

        }
    },
    methods: {
    navigate(path) {
      this.$router.push({ path });
    },
    }
  }
</script>
