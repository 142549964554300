<template>
    <v-row class="ma-0">
        <v-col cols="12">
            <v-form ref="form">
                <v-card>
                    <v-card-title>{{ (this.action ==='create') ? 'Crear Cliente' : 'Editar Cliente'}}</v-card-title>
                    <v-row class="ma-0 pa-1 align-center" no-gutters v-if="formClient.length > 0">
                        <v-col cols="12" md="4" class="px-1" v-if="!formClient[1].hide">
                            <v-text-field
                                v-model="formClient[1].value"
                                label="Nombre"
                                dense
                                filled
                                outlined
                                :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="px-1" md="4" v-if="!formClient[0].hide">
                            <v-text-field
                                v-model="formClient[0].value"
                                label="Apellido"
                                dense
                                filled
                                outlined
                                :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1" v-if="!formClient[2].hide">
                            <v-text-field
                                v-model="formClient[2].value"
                                label="Email"
                                dense
                                filled
                                outlined
                                :rules="[rules.required, ifExists]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1" v-if="!formClient[5].hide">
                            <v-select
                                :items="genders"
                                item-value="type"
                                item-text="label"
                                solo
                                dense
                                placeholder="Seleccione sexo"
                                v-model="formClient[5].value"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1" v-if="!formClient[7].hide">
                            <v-select
                                :items="provinces"
                                item-value="id"
                                item-text="name"
                                solo
                                dense
                                @change="changeProvince"
                                placeholder="Seleccione provincia"
                                v-model="formClient[7].value"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1" v-if="!formClient[8].hide">
                            <v-select
                                :items="departments"
                                v-model="formClient[8].value"
                                item-value="id"
                                item-text="name"
                                solo
                                dense
                                placeholder="Seleccione departamento"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1" v-if="!formClient[3].hide">
                            <v-text-field
                                v-model="formClient[3].value"
                                label="Telefono"
                                dense
                                filled
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1" v-if="!formClient[4].hide">
                            <v-text-field
                                v-model="formClient[4].value"
                                label="DNI"
                                dense
                                filled
                                outlined
                            ></v-text-field>
                        </v-col>


                        <v-col cols="12" md="4" class="px-1" v-if="!formClient[6].hide">
                            <v-text-field
                                v-model="formClient[6].value"
                                label="Direccion"
                                dense
                                filled
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1" v-if="!formClient[10].hide">
                            <v-text-field
                                v-model="formClient[10].value"
                                label="CUIL"
                                dense
                                filled
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1" v-if="!formClient[11].hide">
                            <v-text-field
                                v-model="formClient[11].value"
                                label="Codigo postal"
                                dense
                                filled
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="px-1" v-if="!formClient[9].hide">
                            <v-text-field
                                v-model="formClient[9].value"
                                label="Fecha Naci."
                                dense
                                filled
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row >
                        <v-col class="d-flex justify-end">
                            <v-btn block small color="primary" :disabled="!formClient.some( e => !e.hide)" @click="saveClient">Guardar</v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-form>
        </v-col>

    </v-row>
</template>

<script>
export default {
    name: 'ClientCreateEdit',
    props:{
        action: {
            type: String,
            required: true
        },
        profile: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            exists: false,
            rules: {
                min: v => v.length >= 8 || 'Min 8 caracteres',
                required: v => !!v || 'Este dato es Requerido',
                requiredMultiple: v => v.length > 0 || 'Este dato es Requerido',
            },
            provinces: [],
            departments: [],
            genders: [
                {
                    type: 'f',
                    label:'Femenino'
                },
                {
                    type: 'm',
                    label:'Masculino'
                },
                {
                    type: 'n',
                    label:'Sin especificar'
                }
            ],
            formClient: [],
        };
    },
    computed: {
        ifExists(){
            if (this.exists === true){
                return "Email ya existe"
            } else {
                return true
            }
        },
        clientEmail() {
            return this.formClient.map( item => item.value );
        }
    },
    watch: {
        action(newValue){
            if(newValue === 'create'){
                this.initial()
            }
        },
        clientEmail(newValue, oldValue){
            if(newValue[2] !=  oldValue[2]){
                this.exists = false
            }
        }
    },
    mounted() {
        this.initial()
        this.getProvinces()
        this.getDepartments(66)
        if(this.action === 'edit'){
            this.formClient.forEach( e => {
                if (e.name === 'lastname')  e.value = this.profile.lastname
                if (e.name === 'firstname') e.value = this.profile.firstname
                if (e.name === 'email') e.value = this.profile.user.email
                if (e.name === 'mobile_phone') e.value = this.profile.mobile_phone
                if (e.name === 'dni') e.value = this.profile.dni
                if (e.name === 'gender') e.value = this.profile.gender
                if (e.name === 'dni') e.value = this.profile.dni
                if (e.name === 'address') e.value = this.profile.address
                if (e.name === 'province_id') e.value = this.profile.province_id
                if (e.name === 'department_id') e.value = this.profile.department_id
                if (e.name === 'birthday') e.value = this.profile.birthday
                if (e.name === 'cuil') e.value = this.profile.cuil
                if (e.name === 'cp') e.value = this.profile.cp
            })
        }
    },

    methods: {
        initial(){
            this.formClient = [
                { id: 0, label: 'Apellido', name: 'lastname', value: null, hide: false },
                { id: 1, label: 'Nombre', name: 'firstname', value: null, hide: false },
                { id: 2, label: 'Email', name: 'email', value: null, hide: false },
                { id: 3, label: 'Telefono', name: 'mobile_phone', value: null, hide: false },
                { id: 4, label: 'DNI', name: 'dni', value: null, hide: false },
                { id: 5, label: 'Sexo', name: 'gender', value: 'f', hide: false },
                { id: 6, label: 'Address', name: 'address', value: null, hide: false },
                { id: 7, label: 'Provincia', name: 'province_id', value: 66, hide: false },
                { id: 8, label: 'Departamento', name: 'department_id', value: 66028, hide: false },
                { id: 9, label: 'F. Nacimiento', name: 'birthday', value: null, hide: false },
                { id: 10, label: 'CUIL', name: 'cuil', value: null, hide: false },
                { id: 11, label: 'Codigo Postal', name: 'cp', value: null, hide: false },
            ]
        },
        saveClient(){
            this.exists = ""
            if(this.$refs.form.validate()){

                const data = this.preparateData()

                if( this.action === 'create'){
                    this.$http.post(process.env.VUE_APP_URI + 'profiles', data )
                    .then( res => {
                        console.log('ok created', res.data.data);
                        this.$emit('clientCreated');
                    })
                    .catch( err => {
                        if(err.response.status === 422){
                            const data = err.response.data
                            if(data.message === 'errorValidation'){
                                if(data.data.email) {
                                    this.exists = true
                                }
                            }
                        }
                    })
                } else {
                    this.$http.put(
                    process.env.VUE_APP_URI + `profiles/${this.profile.id}`, data )
                    .then( res => {
                        console.log('ok updated', res.data.data);
                        this.$emit('clientCreated');
                    })
                    .catch( err => console.log(err))
                }

            }
        },
        changeProvince(){
            this.getDepartments(this.formClient[7].value)
        },
        changeDepartment(){

        },
        getProvinces(){
            this.$http.get(process.env.VUE_APP_URI + 'provinces')
                .then( res => {
                    this.provinces = res.data.data
                })
        },
        getDepartments(value){
            this.$http.get(process.env.VUE_APP_URI + `departments/${value}`)
                .then( res => {
                    this.departments = res.data.data
                })
        },
        preparateData(){
            return this.formClient
                .filter( e => e.value != null && e.value != '' )
                .map( el => {
                    const obj = {};
                    obj[el.name] = el.value
                    return obj
                })
                .reduce((acc, cur) => {
                    const [key, value] = Object.entries(cur)[0];
                    acc[key] = value;
                    return acc;
                }, {})
        }
    },
};
</script>

<style scoped>

</style>
