<template>
    <div>
        <v-list-item>
            <v-list-item-content>
            <v-list-item-title class="text-h6">
                Opciones
            </v-list-item-title>
            <v-list-item-subtitle>
                acciones
            </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list dense nav >
            <v-list-item link v-for="(option, i) of menu" :key="i">
                <router-link :to="{name: option.path}">
                    <v-list-item-title v-text="option.name"></v-list-item-title>
                </router-link>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
export default {
    name: 'MenuComponent',

    data() {
        return {
            menu: [
                {name: "Inicio", path: 'home'},
                {name: "Clientes", path: 'clients'},
                {name: "Productos", path: 'products'},
                {name: "Ventas", path: 'sales'},
                {name: "Compras", path: 'shoppings'},
            ]
        };
    },

    mounted() {
    },

    methods: {
    },
};
</script>

<style scoped>

</style>
