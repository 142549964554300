<template>
    <ShoppingsComponent />
</template>
<script>
    import ShoppingsComponent from "@/components/shoppings/ShoppingsComponent";

    export default {
    name: "ShoppingsView",

    components: {
        ShoppingsComponent,
    },
    };
  </script>
