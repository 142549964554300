const titles = [
    { path:'clients', title: 'Clientes' },
    { path:'home', title: 'Dashboard'},
    { path:'products', title: 'Productos'},
    { path:'sales', title: 'Ventas'},
    { path:'shoppings', title: 'Compras'},
]

export const title = {
    state: {
        header: 'Dashboard',
    },
    mutations: {
        updateHeader: (state, data) => {
            const title = titles.find( e => e.path === data).title
            state.header = title
        }
    },
    actions: {
        updateTitle: (context, data) => {
            context.commit('updateHeader', data)
        }
    }
}
