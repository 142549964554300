<template>
    <div>
        <v-card-title>Buscar</v-card-title>
        <v-row class="ma-0">
        <v-col cols="12" md="4">
            <v-select
            v-model="filterSelected"
            :items="itemsFiltered"
            item-value="id"
            item-text="label"
            chips
            deletable-chips
            solo
            dense
            @change="changeFilter"
            placeholder="Agregar filtro"
            ></v-select>

            <v-chip v-for="(item, index) of formSearch.filter( e => !e.hide)" :key="index"
            class="ma-2"
            close
            @click:close="formSearch[item.id].hide = true; filterSelected = null; formSearch[item.id].value = null"
            >
                {{item.label}}
            </v-chip>
        </v-col>
        <v-col cols="12" md="8">
            <v-form>
                <v-card>
                <v-row class="ma-0 pa-1 align-center" no-gutters>
                    <v-col cols="12" class="px-1" md="4" v-if="!formSearch[0].hide">
                        <v-text-field
                            v-model="formSearch[0].value"
                            label="Apellido"
                            dense
                            filled
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="px-1" v-if="!formSearch[1].hide">
                        <v-text-field
                            v-model="formSearch[1].value"
                            label="Nombre"
                            dense
                            filled
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="px-1" v-if="!formSearch[2].hide">
                        <v-text-field
                            v-model="formSearch[2].value"
                            label="DNI"
                            dense
                            filled
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="px-1" v-if="!formSearch[3].hide">
                        <v-text-field
                            v-model="formSearch[3].value"
                            label="Provincia"
                            dense
                            filled
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="px-1" v-if="!formSearch[4].hide">
                        <v-text-field
                            v-model="formSearch[4].value"
                            label="Departamento"
                            dense
                            filled
                            outlined
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4" class="px-1" v-if="!formSearch[5].hide">
                        <v-text-field
                            v-model="formSearch[5].value"
                            label="CUIL"
                            dense
                            filled
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="px-1" v-if="!formSearch[6].hide">
                        <v-text-field
                            v-model="formSearch[6].value"
                            label="Email"
                            dense
                            filled
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="px-1" v-if="!formSearch[7].hide">
                        <v-text-field
                            v-model="formSearch[7].value"
                            label="Direccion"
                            dense
                            filled
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="px-1" v-if="!formSearch[8].hide">
                        <v-text-field
                            v-model="formSearch[8].value"
                            label="Telefono"
                            dense
                            filled
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="px-1" v-if="!formSearch[9].hide">
                        <v-text-field
                            v-model="formSearch[9].value"
                            label="Codigo Postal"
                            dense
                            filled
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="px-1" v-if="!formSearch[10].hide">
                        <v-text-field
                            v-model="formSearch[10].value"
                            label="Sexo"
                            dense
                            filled
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="px-1" v-if="!formSearch[11].hide">
                        <v-text-field
                            v-model="formSearch[11].value"
                            label="FEcha Nac."
                            dense
                            filled
                            outlined
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row >
                    <v-col class="d-flex justify-end mr-3">
                        <v-btn small color="primary" :disabled="!formSearch.some( e => !e.hide)" @click="applyFilter">Aplicar filtro</v-btn>
                    </v-col>

                </v-row>
                </v-card>
            </v-form>
        </v-col>

        </v-row>
    </div>
</template>

<script>
export default {
    name: 'ClientFilter',
    props: {
        filters: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            filterSelected: null,
            formSearch: [
                { id: 0, label: 'Apellido', name: 'lastname', value: null, hide: false },
                { id: 1, label: 'Nombre', name: 'firstname', value: null, hide: false },
                { id: 2, label: 'DNI', name: 'dni', value: null, hide: true },
                { id: 3, label: 'Provincia', name: 'province_id', value: null, hide: true },
                { id: 4, label: 'Departamento', name: 'department_id', value: null, hide: true },
                { id: 5, label: 'CUIL', name: 'cuil', value: null, hide: true },
                { id: 6, label: 'Email', name: 'email', value: null, hide: true },
                { id: 7, label: 'Address', name: 'address', value: null, hide: true },
                { id: 8, label: 'Telefono', name: 'mobile_phone', value: null, hide: true },
                { id: 9, label: 'Codigo Postal', name: 'code_postal', value: null, hide: true },
                { id: 10, label: 'Sexo', name: 'gender', value: null, hide: true },
                { id: 11, label: 'F. Nacimiento', name: 'birthday', value: null, hide: true },
            ],
            optionsFilter: [],

        };
    },
    computed: {
        itemsFiltered (){
            return this.formSearch.filter( e => e.hide && e.name != 'birthday')
        }
    },
    mounted() {
        this.formSearch = (this.filters.length > 0) ?  this.filters : this. formSearch
        this.optionsFilter = this.formSearch.filter(e => e.id != 11)//.map( e => e.name)
    },
    methods: {
        changeFilter(){
            this.formSearch[this.filterSelected].hide = false
        },
        applyFilter(){
            this.formSearch.forEach( e => {
                if(e.value === null || e.value === ''){
                    e.hide = true
                } else {
                    e.hide = false
                }
            })
            this.$emit('clientFilterAction', this.formSearch)
        }
    },
};
</script>

<style scoped>

</style>
