<template>
    <v-container fluid fill-height>
        <v-row>
            <v-col class="col-12 d-flex justify-center align-center align-content-center">
                <v-col class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 bg" >
                    <LoginForm />
                </v-col>
            </v-col>
        </v-row>
</v-container>
</template>
<script>
import LoginForm from "@/components/LoginForm.vue"
export default {
  name: "LoginView",
  components: { LoginForm },
  data() {
    return {
    };
  },
  methods: {},
};
</script>
<style scoped>
    .bg {
        background-color: rgb(190, 209, 206);
    }
</style>
