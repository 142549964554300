import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from 'axios';

axios.defaults.withCredentials = true;

Vue.config.productionTip = false;

Vue.prototype.$http = axios;

Vue.prototype.$store = store;

axios.interceptors.request.use( (config) => {
    const { token } = store.state.authentication;
    if (token) {
        config.headers.Authorization =  `Bearer ${token}`;
        if (config.url === process.env.VUE_APP_URI + 'images') {
            config.headers['Content-Type'] = 'multipart/form-data';
        }
    }
    return config;
}, (err) => {
    return Promise.reject(err);
});

// axios.get('https://api-dev.deportivasalta.com/sanctum/csrf-cookie').then(response => {
//     console.log(response)
    new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App),
      }).$mount("#app");
//});


