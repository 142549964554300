<template>
    <v-row>
        <v-col cols="12">
            <v-file-input
            v-model="imageFile"
            show-size
            counter
            ref="fileInput"
            chips
            multiple
            accept="image/*"
            prepend-icon="mdi-camera"
            @change="uploadFile"
            ></v-file-input>
            <!-- <v-progress-circular
            v-if="params.loadingFile"
            indeterminate
            color="primary"
            ></v-progress-circular> -->
            <v-card v-if="params.loadingOk && image.length > 0">
            </v-card>
        </v-col>
        <v-col cols="12" v-if="images.length > 0">
            <v-row>
                <v-col cols="12" md="4" lg="3" v-for="(image, i) of images" :key="i">
                    <v-row>
                        <v-col cols="12" class="d-flex flex-row justify-center">
                            <v-img :src="image.url" height="500"></v-img>
                        </v-col>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="4"><v-icon @click="changeOrder(i, 'left')"  v-if="i > 0">mdi-arrow-left</v-icon></v-col>
                                <v-col cols="4" ><v-icon v-if="image.file" @click="deleteImage(i)">mdi-delete</v-icon></v-col>
                                <v-col cols="4"><v-icon @click="changeOrder(i, 'right')" v-if="images.length > (i + 1)">mdi-arrow-right</v-icon></v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import emptyImage from '@/assets/empty_image.png'

export default {
    name: 'ProductImages',
    props: {
        action: {
            type: String,
            default: 'create'
        },
        listImages: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            imageFile: null,
            emptyImage: emptyImage,
            productImageSelected: 1,
            image:'',
            ruleImg: [ 'Formato no Aceptado'],
            rules: {
                min: v => v.length >= 8 || 'Min 8 caracteres',
                required: v => !!v || 'Este dato es Requerido',
                requiredMultiple: v => v.length > 0 || 'Este dato es Requerido',
            },
            params: {
                sendNow:true,
                loadingOk:false,
                loadingFile:false,
                recipientsSelectedQty:0,
                menu1:false,
                modal2:false,
                showPreview:false,
                sendToAll: false,
                labelSendNow:"Envío Inmediato",
                labelImg:"JPG o PNG. Tamaño  862 x 485 pixeles.",
            },
            images:[],

        };
    },

    mounted(){
        if(this.action === 'create'){
            this.initialImage()
        } else {
            console.log('editing....');
            this.images = this.listImages
            if(this.images.length === 0){
                 const data = {
                url: emptyImage,
                file: null,
                order: this.images.length + 1
                }
                this.images.push({...data});
            }
        }
    },

    methods: {
        initialImage(){
            const data = {
                url: emptyImage,
                file: null,
                order: this.images.length + 1
            }
            this.images.push({...data});
        },
        deleteImage(index){
            const isLastElement =  ( (this.images.length - 1) === index ) ? true : false
            console.log('islast', index)

            if(!isLastElement){
                let orderDeleted = this.images.find( (el, i) => i === index ).order
                this.images = this.images.filter( (e, i) => i != index)

                this.images.forEach( (el, i) => {
                    if( i >= index){
                        el.order = orderDeleted
                        orderDeleted++
                    }
                })

            } else {
                this.images = this.images.filter( (e, i) => i != index)
                if(this.images.length === 0) {
                    this.initialImage()
                }
            }

            this.$emit('updateFiles', this.images)
        },

        changeOrder(index, direction){
            let data = [ ...this.images]
            const i = index + 1

            if(direction === 'left'){
                data[index].order = i - 1;
                data[index - 1].order = i;

                [data[index], data[index - 1]] = [data[index -1 ], data[index]]
            }
            if(direction === 'right'){
                data[index].order = i + 1;
                data[index + 1].order = i;

                [data[index], data[index + 1]] = [data[index + 1 ], data[index]]
            }
            this.images = data

            this.$emit('updateFiles', this.images)
        },

        async uploadFile(){
            if (this.imageFile) {
                const findEmpty = this.images.findIndex(el => el.file === null)
                if(findEmpty > -1) this.images.splice(findEmpty,1)
                //if (this.imageFile.type === 'image/png' || this.imageFile.type === 'image/jpeg'){
                if(this.imageFile.length > 0){
                // let fileName
                // if (this.imageFile.type === 'image/png') {
                //     fileName = 'image1' + '.png'
                // }
                // if (this.imageFile.type === 'image/jpeg') {
                //     fileName = 'image1' + '.jpg'
                // }


                let formData = new FormData();
                // this.images.forEach((obj, index) => {

                // // Agregar el ID y el nombre como campos de texto
                // formData.append(`data[${index}][order]`, obj.order);
                // });
                let imageItems = [];
                this.imageFile.forEach( (file, index) => {
                    formData.append('id[]', (index + 1));
                    formData.append('images[]', file);
                    const newItemImage = {
                        order: imageItems.length + 1,
                        file,
                        image_id: null,
                        id: index + 1
                        }
                    imageItems.push( newItemImage );
                })

                this.$http
                        .post(process.env.VUE_APP_URI + 'images', formData)
                        .then(async (res) => {
                                let lastOrder = this.images.length;

                                const promises = imageItems.map((item) => {
                                return new Promise((resolve) => {
                                    const reader = new FileReader();
                                    reader.onload = () => {
                                    item.url = reader.result;
                                    item.image_id = res.data.data.find((e) => Number(e.id) == item.id).image_id;
                                    resolve();
                                    };
                                    reader.readAsDataURL(item.file);
                                });
                                });

                                await Promise.all(promises);

                                imageItems.forEach((item) => {
                                    lastOrder = lastOrder + 1
                                    item.order = lastOrder
                                });


                                this.images.push(...imageItems)

                                this.$emit('updateFiles', this.images);
                        })
                        .catch(error => {
                            console.log({ error });
                        });

            } else {
                this.archivo = null;
                this.params.labelImg = 'error';
                console.log('formato no aceptado');
            }
            }
        },
    },
};
</script>

<style scoped>

</style>
