<template>
    <v-row justify="center">
        <v-dialog
        v-model="dialog"
        persistent
        width="auto"
        >
        <v-card>
            <v-card-title class="text-h5">
            {{ title }}
            </v-card-title>
            <v-card-text>{{ message }}</v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green-darken-1"
                variant="text"
                @click="close"
            >
                Cancelar
            </v-btn>
            <v-btn
                color="green-darken-1"
                variant="text"
                @click="accept()"
            >
                Aceptar
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
  </v-row>
</template>

<script>
export default {
    name: 'modalSave',

    data() {
        return {
            dialog: true
        };
    },
    props: {
        action: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        message: {
            type: String,
            default: ''
        }
    },
    methods: {
        accept(){
            this.$emit('save')
            this.close()
        },
        close(){
            this.dialog= false
            this.$emit('close')
        }
    },
};
</script>

<style scoped>

</style>
