<template>
    <div>
        <v-row v-if="typeView" class="mt-3">
            <v-col cols="3">
                <p v-for="(item, index) of filters.filter( e =>  e.value != '' &&  e.value != null)" :key="index">
                <v-chip
                class="ma-2"
                close
                @click:close="removeFilter(item.id)"
                >
                    {{item.label}}: <span class="ml-2" color="green"> {{ item.valueDescription }}</span>
                </v-chip>
                </p>

            </v-col>
            <v-col cols="9">
                <v-row>
                    <v-col cols="4" v-for="(item,index) of items" :key="index">
                        <v-card id="card_product" >
                             <v-img
                            lazy-src="https://picsum.photos/id/11/10/6"
                            max-height="150"
                            max-width="250"
                            :src="urlImage(item.image.name)"
                            >
                            </v-img>
                            <p class="mt-2 mb-0">{{ capitalizeFirstLetter(item.name) }}</p>
                            <p class="ma-0">{{ capitalizeFirstLetter(item.category.description) }}</p>
                            <p class="ma-0">{{ capitalizeFirstLetter(item.brand.description) }}</p>
                            <p class="ma-0">Talles: {{ sizes( item.price ) }}</p>
                            <p class="ma-0">Stock: {{ (item.price) ? stock(item.price)  : '' }}</p>
                            <div v-if="!hasDifferentsPrices(item.price)">
                                $ {{ item.price[0].selling_price }}
                            </div>
                            <div v-else>
                                <div v-if="item.price">
                                    <div v-for="( price, i ) of item.price" :key="i">
                                        <span>Talle: {{ price.size.description }} Precio:{{ price.selling_price }}</span>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- <v-row v-if="formSearch.filter( e => e.value != '' && e.value != null).length > 0"> -->
        <v-row v-if="items && !typeView">
            <v-col cols="12">
                <v-chip v-for="(item, index) of filters.filter( e =>  e.value != '' &&  e.value != null)" :key="index"
                class="ma-2"
                close
                @click:close="removeFilter(item.id)"
                >
                    {{item.label}}: <span class="ml-2" color="green"> {{ item.valueDescription }}</span>
                </v-chip>
            </v-col>
        </v-row>
        <v-row class="align-center">
            <v-col cols="16">
                <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-1"
                item-key="id"
                loading="true"
                >

                <template v-slot:[`item.images`]=" { item } ">
                    <v-img
                    v-if="item.images.length > 0"
                    class="ma-2"
                    max-height="60"
                    max-width="80"
                    :src="showImage(item.images)"
                    >
                    </v-img>
                </template>

                <template v-slot:[`item.stock`]="{ item }" >
                    {{ (item.price) ? stock(item.price)  : '' }}
                    <v-tooltip
                        v-if="showStockSize(item)"
                        :open-delay="400"
                        dense
                        content-class="tooltip-cool"
                        top>
                        <!-- :disabled="actionPermission('DEL')" -->

                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="ml-2"
                                x-small
                                v-bind="attrs"
                                v-on="on"
                                @click="openDialog(item)"
                                >
                                <v-icon color="green" x-small>mdi-eye</v-icon>
                            </v-btn>
                        </template>
                        <div v-html="showStockSize(item)" >
                        </div>
                    </v-tooltip>
                </template>

                <template v-slot:[`item.sizes`]="{ item }" >
                    {{ sizes( item.price ) }}
                </template>

                <template v-slot:[`item.prices`]="{ item }" >
                    <div v-if="!hasDifferentsPrices(item.price)">
                        $ {{ item.price[0].selling_price }}
                    </div>
                    <div v-else>
                        <div v-if="item.price">
                            <div v-for="( price, i ) of item.price" :key="i">
                                <span>Talle: {{ price.size.description }} Precio:{{ price.selling_price }}</span>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip
                        :open-delay="400"
                        dense
                        content-class="tooltip-cool"
                        top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                @click="editModal(item)"
                                >
                                <v-icon color="success" small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                        <v-tooltip
                            :open-delay="400"
                            dense
                            content-class="tooltip-cool"
                            top>
                            <!-- :disabled="actionPermission('DEL')" -->
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="editModal(item)"
                                    >
                                    <v-icon  color="red" small>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Eliminar</span>
                        </v-tooltip>
                </template>
                </v-data-table>
            </v-col>
        </v-row>
         <v-dialog
        v-model="dialog"
        persistent
        width="400"
        >
        <v-card fuild class="pa-2 ma-0">
            <v-data-table
            :headers="headerStockSizes"
            :items="stockSizes"
             hide-default-footer
            >

            </v-data-table>
            <v-btn block @click="dialog = false">
                Cerrar
            </v-btn>
        </v-card>
         </v-dialog>
    </div>
</template>

<script>
import imageDefault from '@/assets/empty_image.png'
export default {
    name: 'ProductTable',
    props: {
        items: {
            type: Array,
            required: true
        },
        filters: {
            type: Array
        }
    },
    data() {
        return {
            dialog: false,
            stockSizes: [],
            typeView:false,
            emptyImage: imageDefault,
            headerStockSizes: [
                { text: 'Talle', value: 'size'},
                { text: 'Cant. Stock', value: 'totalStock'},
            ],
            headers:[
                { text:"Image", value: "images"},
                { text:"Id", value: "id"},
                { text:"Nombre", value: "name"},
                { text:"Categoria", value: "category.description"},
                { text:"Marca", value: "brand.description"},
                { text:"Stock", value: "stock"},
                { text:"Precio", value: "prices" },
                // { text:"Talles", value: "sizes" },
                // {text:"Telefono", value: "mobile_phone"},
                // {text:"Email", value: "user.email"},
                // {text:"DNI", value: "dni"},
                {text:"Acciones", value: "actions"},
            ]
        };
    },
    computed: {
        showStockSize(){
            return (item) => {
                console.log(item);
                let data = null
                item.price.forEach( el => {
                    if( el.total_stock && el.total_stock > 0 ){
                        if(!data) {
                            data = `<span>Talle ${el.size.description}: <strong>${el.total_stock}</strong></span><br>`
                        } else {
                            data += `<span>Talle ${el.size.description}: <strong>${el.total_stock}</strong></span><br>`
                        }
                    }
                })
                return data
            }
        },
        showImage() {
            return (images) => {
                return process.env.VUE_APP_URI_IMAGE + images[0].name;
            }
        },
        urlImage(){
            return (value) => {
                if(value != 'empty_image.png'){
                    return this.emptyImage = process.env.VUE_APP_URI_IMAGE + value
                } else {
                    return imageDefault
                }
            }
        },
        capitalizeFirstLetter() {
            return (string) => {
                let data = string.toLowerCase();
                return data.charAt(0).toUpperCase() + data.slice(1);
           }
        },
        hasDifferentsPrices(){
            return (item) => {
                let hasDifferentsPrices = false
                let priceDefault = item[0].selling_price
                item.forEach( e => {
                    if(e.selling_price != priceDefault) {
                        hasDifferentsPrices = true
                        return
                    }
                })
                return hasDifferentsPrices
            }
        },
        stock() {
            return (item) => {
                const stock = item.map( e => e.total_stock).reduce((accum, number) => {
                    return Number(accum) + Number(number)
                }, 0)
                return  stock
            }
        },
        sizes() {
            return (item) => {
                const regex = /,/g;
                return item.map( e => e.size).map( e => e.description).toString().replace(regex, ' - ')
            }
        },

        prices() {
            return (item) => {
                const prices = item.map( e => {
                    return {
                        size: e.size.description,
                        price: e.selling_price
                    }
                })
                return  prices
            }
        }
    },

    methods: {
        openDialog(item){
            this.stockSizes = item.price.filter( e => e.total_stock)
                .map( el => {
                    return {
                        size: el.size.description,
                        totalStock: el.total_stock
                    }
            })
            this.dialog = true
        },
        editModal(item){
            console.log('emit..');
            this.$emit('createEditProduct', item)
        },
        removeFilter(value){
            this.$emit('removeFilter', value)
        },
    },
};
</script>

<style scoped>
#card_product{
    font-size: 14px;
}
</style>
