import { render, staticRenderFns } from "./ProductImages.vue?vue&type=template&id=9172c8ba&scoped=true&"
import script from "./ProductImages.vue?vue&type=script&lang=js&"
export * from "./ProductImages.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9172c8ba",
  null
  
)

export default component.exports