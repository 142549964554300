<template>
<v-app class="bg">
  <v-navigation-drawer v-model="drawer" app v-if="this.$store.state.authentication.authenticated">
    <v-card>
        <MenuView />
    </v-card>
  </v-navigation-drawer>

<v-app-bar app v-if="this.$store.state.authentication.authenticated">
    <v-app-bar-nav-icon @click="drawer = !drawer">
    </v-app-bar-nav-icon>
    <v-row>
        <v-col><v-toolbar-title>{{ title }}</v-toolbar-title></v-col>
        <v-col></v-col>
    </v-row>

    <v-toolbar-title>
        <v-btn  class="my-4 ml-5" block @click="$router.push({ name:'home' });">
            Inicio
        </v-btn>
    </v-toolbar-title>
</v-app-bar>

  <v-main v-if="this.$store.state.authentication.authenticated">
    <v-container fluid class="px-0">
     <router-view></router-view>
    </v-container>
  </v-main>

  <v-main v-else>
    <router-view></router-view>
  </v-main>
</v-app>
</template>

<script>
import MenuView from "@/components/MenuComponent"
export default {
    name: "App",
    components: { MenuView },
    data: () => ({
        drawer: false,
    }),
    computed:{
        title (){
            return this.$store.state.title.header
        }
    }
};
</script>
<style scoped>

.bg{
    background-image: linear-gradient(to bottom, #57707c, #5f8597, #679ab3, #6eb0d1, #75c6ef);
}
</style>
