<template>
    <v-row class="ma-0">
        <v-col cols="12">
            <v-form ref="form">
                <v-card>
                    <v-card-title>{{ (this.action ==='create') ? 'Nueva Compra' : 'Editar Compra'}}</v-card-title>
                    <v-row class="ma-0 pa-1 align-center" no-gutters v-if="formProvider.length > 0">
                        <v-col cols="12" md="4" class="px-1" v-if="!formProvider[0].hide">
                            <v-select
                                :items="providers"
                                item-value="id"
                                item-text="name"
                                solo
                                dense
                                @change="changeProvider"
                                v-model="formProvider[0].value"
                            ></v-select>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="12" md="4" class="px-1" v-if="!formProvider[1].hide">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="formProvider[1].value"
                                    label="Fecha"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[rules.required]"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="formProvider[1].value"
                                    no-title
                                    scrollable
                                    >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false">
                                    Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(date)">
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                     <v-row >
                        <v-col class="d-flex justify-start ml-3">
                            <v-btn small color="primary" :disabled="formProvider[0].value == 0" @click="openDialog">Agregar Producto</v-btn>
                        </v-col>
                    </v-row>

                    <!-- Datatable -->
                    <v-row>
                        <v-col cols="12">
                            <v-data-table
                                :headers="headers"
                                :items="items"
                                class="elevation-1"
                                item-key="id"
                            >
                            <template v-slot:[`item.image`]=" { item } ">
                                <v-img

                                max-height="60"
                                max-width="80"
                                :src='item.image'
                                >
                                </v-img>
                            </template>

                             <template v-slot:[`item.color`]="{ item, index }">
                                <v-select
                                :items="sizeItems(item, index)"
                                v-model="item.color"
                                item-value="id"
                                item-text="description"
                                solo
                                dense
                                class="pt-4"
                                ></v-select>
                            </template>
                            <!-- :disabled="item.disabled" -->
                            <template v-slot:[`item.cost_price`]="{ item }">
                                <v-text-field
                                    v-model="item.cost_price"
                                    :rules="[rules.required, rules.numberValid]"
                                    :disabled="true"
                                ></v-text-field>
                            </template>

                            <template v-slot:[`item.selling_price`]="{ item }">
                                <v-text-field
                                    v-model="item.selling_price"
                                    :rules="[rules.required, rules.numberValid]"
                                    :disabled="true"
                                ></v-text-field>
                            </template>

                            <template v-slot:[`item.quantity`]="{ item }">
                                <v-text-field
                                    v-model="item.quantity"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </template>

                            <template v-slot:[`item.subtotal`]="{ item }">
                                <v-text-field
                                    v-model="item.subtotal"
                                    :disabled="true"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-tooltip
                                    :open-delay="400"
                                    dense
                                    content-class="tooltip-cool"
                                    top>
                                    <!-- :disabled="actionPermission('DEL')" -->
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-if="shoppingStatus === '1'"
                                            small
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="editProduct(item)"
                                            >
                                            <v-icon color="blue" x-small>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Edit Product</span>
                                </v-tooltip>
                                <v-tooltip
                                    :open-delay="400"
                                    dense
                                    content-class="tooltip-cool"
                                    top>
                                    <!-- :disabled="actionPermission('DEL')" -->
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-if="shoppingStatus === '1'"
                                            small
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="deleteItem(item)"
                                            >
                                            <v-icon color="red" x-small>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Eliminar</span>
                                </v-tooltip>
                            </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row >
                        <v-col class="d-flex justify-end mr-3">
                            <span class="mr-5">$ {{ shoppingTotal }}</span>
                            <v-btn
                                class="mr-3"
                                small
                                color="primary"
                                :disabled="items.length === 0 || shoppingStatus === '2'"
                                @click="save('draft')">Guardar Borrador
                            </v-btn>
                             <v-btn
                                small
                                color="success"
                                :disabled="(action === 'create' || !shoppingId || shoppingStatus === '2') || existsProductStockNull"
                                @click="saveStock()">Guardar y Agregar Stock
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-form>
        </v-col>

        <!-- Modal search product -->
        <v-dialog
        v-model="dialog"
        persistent
        width="1000"
        >
        <v-card fuild class="pa-0 ma-0">

            <v-card-title>
                Seleccione un producto de {{ providers.find( (e, index) => index === formProvider[0].value).name }}
            </v-card-title>
            <v-row class="pa-0 ma-0">
                <v-col cols="4">
                    <v-text-field
                        v-model="formSearch.productName"
                        label="Nombre de producto"
                    ></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-select
                        :items="providerProductBrand"
                        v-model="formSearch.productBrand"
                        item-value="id"
                        item-text="description"
                        label="Marca"
                    ></v-select>
                </v-col>
                <v-col cols="2">
                    <v-btn color="primary" dark @click="searchProduct">Buscar Producto</v-btn>
                </v-col>
            </v-row>

            <v-row class="pa-0 ma-0">
                <v-col cols="12">
                    <v-data-table
                        :headers="productFoundheaders"
                        :items="productsFound"
                     >
                        <template v-slot:[`item.image`]=" { item } ">
                                <v-img
                                class="ma-2"
                                max-height="60"
                                max-width="80"
                                :src='item.image'
                                >
                                </v-img>
                        </template>
                        <!-- v-model="item.color" -->
                        <template v-slot:[`item.sizes`]="{ item }">
                            <v-select
                            :items="sizes(item)"
                            v-model="item.sizeSelected"
                            item-value="id"
                            item-text="description"
                            multiple
                            dense
                            class="pt-4"
                            ></v-select>
                        </template>

                        <template v-slot:[`item.select`] = "{ item }">
                            <v-btn
                                color="success"
                                :disabled="!item.sizeSelected"
                                @click="actionSelect(item, item.sizeSelected)"
                            >
                                Seleccionar
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" >
                <v-col class="d-flex justify-start ml-3">
                    <v-btn small color="primary" :disabled="formProvider[0].value == 0" @click="dialog = false">Cerrar</v-btn>
                </v-col>
            </v-row>
        </v-card>
        </v-dialog>

        <template v-if="actionSave">
            <ModalSave
                @close="actionSave = false"
                @save="save('addStock')"
                title="Esta agregando stock de productos"
                message="Esta operacion no puede volverse atras. Esta segur@ que desea guardar?"
            />
        </template>

        <template v-if="actionEditProduct">
            <!-- @productUpdated="save('addStock')" -->
            <ProductCreateEditModal
                @close="updateDataListItems"
                :product="productEdit"
                :action="actionProduct"
            />
        </template>
    </v-row>
</template>

<script>
import ModalSave from '@/components/commons/ModalSave.vue'
import ProductCreateEditModal from '@/components/products/ProductsCreateEditModal.vue'

export default {
    name: 'ShoppingCreateEdit',
    components: { ModalSave, ProductCreateEditModal},
    props:{
        action: {
            type: String,
            required: true
        },
        shopping: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            emptyImage: null,
            productEdit: null,
            actionProduct: 'edit',
            actionEditProduct: false,
            actionSave: false,
            shoppingStatus: '1',
            shoppingTotal: 0,
            formSearch: {
                productName: null,
                productBrand: null,
            },
            productsFound: [],
            providerProductBrand: [],
            productBrand: 0,
            dialog: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            modal: false,

            exists: false,
            rules: {
                min: v => v.length >= 8 || 'Min 8 caracteres',
                required: v => !!v || 'Este dato es Requerido',
                numberValid: v => (v > 0) || 'Debe ser mayor a cero',
                requiredMultiple: v => v.length > 0 || 'Este dato es Requerido',
            },
            providers: [
                {id: 1,
                 name: 'seleccione'}
            ],
            departments: [],
            //formProvider: [],
            formProvider: [
                { id: 0, label: 'Proveedor', name: 'provider_id', value: 0, hide: false },
                { id: 1, label: 'Fecha', name: 'shopping_date', value: null, hide: false },
            ],
            headers:[
                { text:"Imagen", value: "image"},
                { text:"Producto", value: "name"},
                { text:"Talle", value: "size"},
                { text:"Color", value: "color"},
                { text:"P. Costo", value: "cost_price"},
                { text:"P. Venta", value: "selling_price"},
                { text:"Cantidad", value: "quantity"},
                { text:"Subtotal", value: "subtotal"},
                { text:"Acciones", value: "actions"},
            ],
            productFoundheaders: [
                { text:"Imagen", value: "image"},
                { text: "Product Id", value: "id" },
                { text: "Nombre", value: "name" },
                { text: "Categoria", value: "category.description" },
                { text: "Marca", value: "brand.description" },
                { text: "Talle", value: "sizes" },
                { text: "Acction", value: "select" },
            ],
            items: [],
            shoppingId: null,
            colors: []
        };
    },
    computed: {
        existsProductStockNull(){
            return this.items.some( e => e.product_stock_id === null);
        },
        ifExists(){
            if (this.exists === true){
                return "Email ya existe"
            } else {
                return true
            }
        },
        clientEmail() {
            return this.formProvider.map( item => item.value );
        },
        sizeItems(){
            return (item) => {
                if(this.items.length > 0){

                    const colorsUsed = this.items.filter( element => element.product_id === item.product_id && element.idSize === item.idSize)
                        .filter(el => el.color != item.color)
                        .map( e => e.color)

                    return this.colors.filter( el => !colorsUsed.includes(el.id))
                 } else {
                    return this.colors
                }
            }
        }
    },
    watch: {
        action(newValue){
            if(newValue === 'create'){
                this.initial()
            }
        },
        clientEmail(newValue, oldValue){
            if(newValue[2] !=  oldValue[2]){
                this.exists = false
            }
        },
        items: {
            handler: function (newValue) {
                newValue.forEach( e => {
                    e.subtotal = e.cost_price * e.quantity
                })

                this.shoppingTotal = newValue.reduce( (acc, e) => {
                    return acc + e.subtotal
                }, 0)
            },
            deep: true
        }
    },
    mounted() {
        this.initial()
        this.getColors()
        this.getProviders()
        if(this.action === 'edit'){
            this.shoppingId = this.shopping.id
            this.getShopping(this.shopping.id);
        }
    },

    methods: {
        initial(){
            this.formProvider = [
                { id: 0, label: 'Proveedor', name: 'provider_id', value: 0, hide: false },
                { id: 1, label: 'Fecha', name: 'shopping_date', value: null, hide: false },
            ]
        },
         getColors(){
            this.$http.get(process.env.VUE_APP_URI + 'product_colors')
            .then( res => {
                this.colors = res.data.data
            })
            .catch( err => {
                console.log('err', err);
            })
        },
        updateDataListItems(product = null, data = null,  name = null){
            if(data){
                if(this.items.length > 0){
                    this.items.forEach( el => {
                        if (el.product_id === product.id) {
                            el.name = name
                            el.cost_price = data.find( e => e.idSize == el.idSize.toString()).cost
                            el.selling_price = data.find( e => e.idSize == el.idSize.toString()).sell
                            el.image = data.find( e => e.idSize == el.idSize.toString()).color[0]['product_image_name']
                        }
                    })
                }
            }
            this.actionEditProduct = false
        },
        editProduct(item){
            this.productEdit = {
                id: item.product_id,
                itemStockSelected: item.product_stock_id,
                name: item. name

            }
            this.actionEditProduct = true;
        },
        deleteItem(item){
            this.items = this.items.filter ( e => e.product_stock_id != item.product_stock_id)
        },
        getShopping(shoppingId){
            this.$http.get(
                process.env.VUE_APP_URI + `shoppings/${shoppingId}`)
                .then( res => {
                    this.shoppingStatus = res.data.data.status
                    this.formProvider[0].value = res.data.data.provider_id
                    this.formProvider[1].value = res.data.data.shopping_date

                    res.data.data.shopping_detail.forEach( e => {
                        e.image = process.env.VUE_APP_URI_IMAGE + e.image
                    });
                    this.items = res.data.data.shopping_detail
                })
                .catch( err => console.log(err))
        },
        actionSelect(item, itemSelected){
            //const sizeExists = this.items.some( e => itemSelected.includes(e.idSize)=== itemSelected && e.product_id === item.id)
            //const sizeExists = this.items.some( e => itemSelected.includes(e.idSize) && e.product_id === item.id)

            //if(!sizeExists) {

                itemSelected.forEach( el => {
                    const data = item.price.find( e => e.product_size_id === el);

                    let colorSelected = 1;

                    const colorsUsed = this.items
                        .filter( el => el.product_id === data.product_id)
                        .map( e => e.color)
                    const colorsAvailable = this.colors.filter( e => !colorsUsed.includes(e.id) )

                    if(colorsAvailable.length > 0) {

                        if(colorsAvailable.length > 0){
                            colorSelected = colorsAvailable[0]
                        }

                    }



                    const product = {
                        //product_stock_id:  data.stock[0].id,
                        product_id: data.product_id,
                        idSize: data.product_size_id,
                        image:  process.env.VUE_APP_URI_IMAGE + item.images[0].name,
                        image_id: item.images[0].id,
                        name: item.name,
                        size: data.size.description,
                        color: colorSelected.id, //data.stock[0].product_color.description,
                        cost_price: data.cost_price,
                        selling_price: data.selling_price,
                        quantity: 1,
                        subtotal: null,
                        price_id: data.id,
                        product_stock_id: (data.product_stock_id) ? data.product_stock_id : null
                    }

                    this.items.push(product)

                })
            //}

            // else {
            //     alert('el talle ya existe')
            // }

            this.dialog = false
        },
        sizes(item){

        //     const data = item.price.map( e => {
        //         return {
        //             id: e.stock[0].id,
        //             description: e.size.description,
        //         }
        //    })
      //  return [];
           return item.price.map( e => {
                return {
                    id: e.size.id,
                    description: e.size.description,
                    //product_stock_id: e.stock[0].id
                }
           })
        },
        searchProduct(){
            const provider_id = this.providers.find( (e, index) => index === this.formProvider[0].value).id

            let filter = `?provider_id=${provider_id}&`
            filter += (this.formSearch.productName) ? `name=${this.formSearch.productName}&` : ''
            filter += (this.formSearch.productBrand) ? `product_brand_id=${this.formSearch.productBrand}` : ''
            this.$http.get(
                process.env.VUE_APP_URI + `provider/search_product`+ filter)
                .then( res => {
                    this.productsFound = res.data.data.data.map( product => {
                        if(product.images.length > 0){
                            return {
                                ...product,
                                image: process.env.VUE_APP_URI_IMAGE + product.images[0].name
                            }

                        } else {
                            return product
                        }
                        //console.log(product);

                    })
                })
                .catch( err => console.log(err))
        },
        openDialog(){
           this.formSearch = {
                productName: null,
                productBrand: null,
            },
            this.productsFound = [],
            this.providerProductBrand = [],
            this.getBrandsToProvider()
            this.dialog = true;
        },
        getBrandsToProvider(){
            const provider_id = this.providers.find( (e, index) => index === this.formProvider[0].value).id
            this.$http.get(
                process.env.VUE_APP_URI + `provider_brands/${provider_id}`)
                .then( res => {
                    this.providerProductBrand = res.data.data
                    this.providerProductBrand.unshift({id: 0, description: 'Todas'})
                })
                .catch( err => console.log(err))
        },
        saveStock(){
            this.actionSave = true;
            //this.save('addStock')

        },
        save(action){
            if(this.$refs.form.validate()){

                const data = this.preparateData(action)
                if( this.action === 'create'){
                    this.$http
                    .post(process.env.VUE_APP_URI + 'shoppings', data )
                    .then( () => {
                        this.$emit('ShoppingCreated');
                    })
                    .catch( err => {
                        if(err.response.status === 422){
                            const data = err.response.data
                            if(data.message === 'errorValidation'){
                                if(data.data.email) {
                                    this.exists = true
                                }
                            }
                        }
                    })
                } else {
                    this.$http.put(
                    process.env.VUE_APP_URI + `shoppings/${this.shoppingId}`, data )
                    .then( () => {
                        this.$emit('ShoppingCreated');
                    })
                    .catch( err => console.log(err))
                }

            }
        },
        changeProvider(){
            this.getDepartments(this.formProvider[1].value)
        },
        getProviders(){
            this.$http.get(process.env.VUE_APP_URI + 'providers')
                .then( res => {
                    this.providers = res.data.data
                    this.providers.unshift( { id:0, name:'Selecciona Provedor'} )
                })
        },
        getDepartments(value){
            this.$http.get(process.env.VUE_APP_URI + `departments/${value}`)
                .then( res => {
                    this.departments = res.data.data
                })
        },
        preparateData(action){
           const  data = {
                provider_id: this.formProvider[0].value,
                shopping_date: this.formProvider[1].value,
                shopping_details: this.items.map( e => {
                        return {
                            product_id: e.product_id,
                            product_stock_id: e.product_stock_id,
                            price_id: e.price_id,
                            image_id: e.image_id,
                            cost_price: e.cost_price,
                            quantity: e.quantity,
                            color_id: e.color,
                        }
                }),
                status: (action === 'draft') ? '1' : '2',

                payment_purchases: null

            }
        return data
        }
    },

    // [
    //     {
    //         payment_type_id: 1,
    //         amount: 1200
    //     }
    // ]
};
</script>

<style scoped>

</style>
