import Vue from "vue"

export const authentication = {
    state: {
        authenticated: false,
        user: null,
        token: null
    },
    mutations: {
        addWebToken: (state, data) => {
            state.user = data.user
            state.token = data.token
            state.authenticated = true
        }
    },
    actions: {
        login: (context, data) => {
            return Vue.prototype.$http.post(process.env.VUE_APP_URI + 'login',{
                email: data.email,
                password: data.password
            })
            .then( res => {
                if(res.data.status === 'success'){
                    context.commit('addWebToken', res.data.data)
                }
                return res.data
            })
            .catch( err => console.log(err))
        }
    }
}
