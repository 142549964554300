<template>
    <v-row justify="center">
        <v-dialog
        v-model="dialog"
        persistent
        width="1000"
        >
            <v-card>
                <v-row class="ma-0">
                    <v-col cols="12">
                        <v-form ref="form">
                            <v-card>
                                <v-card-title>{{ (this.action ==='create') ? 'Crear Producto' : 'Editar Producto'}}</v-card-title>
                                <v-row class="ma-0 pa-1 align-center" no-gutters v-if="formProduct.length > 0">
                                    <v-col cols="8">
                                        <v-row class="ma-0 pa-1 align-center">
                                            <v-col cols="12" md="6" class="px-1" v-if="!formProduct[0].hide">
                                                <v-text-field
                                                    v-model="formProduct[0].value"
                                                    label="Nombre"
                                                    dense
                                                    filled
                                                    outlined
                                                    :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" class="px-1" md="6" v-if="!formProduct[1].hide">
                                                <v-text-field
                                                    v-model="formProduct[1].value"
                                                    label="Descripcion"
                                                    dense
                                                    filled
                                                    outlined
                                                    :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6" class="px-1" v-if="!formProduct[2].hide">
                                            <v-select
                                                    :items="categories"
                                                    v-model="formProduct[2].value"
                                                    item-value="id"
                                                    item-text="description"
                                                    solo
                                                    dense
                                                    placeholder="Seleccione categoria"
                                                    :rules="[rules.required]"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="12" md="6" class="px-1" v-if="!formProduct[3].hide">
                                                <v-select
                                                    :items="brands"
                                                    v-model="formProduct[3].value"
                                                    item-value="id"
                                                    item-text="description"
                                                    solo
                                                    dense
                                                    placeholder="Seleccione marca"
                                                    :rules="[rules.required]"
                                                ></v-select>
                                            </v-col>

                                        </v-row>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-row class="ma-0 pa-1 align-center">
                                            <v-col cols="12">
                                                <v-img
                                                lazy-src="https://picsum.photos/id/11/10/6"
                                                max-height="150"
                                                max-width="250"
                                                :src="emptyImage"
                                                >
                                                </v-img>
                                            </v-col>

                                            <v-col cols="12">
                                                <!-- v-if="!params.loadingFile && image.length == 0" -->
                                                <!-- :label="params.labelImg"
                                                :rules="(params.labelImg === 'error') ? ruleImg : [] " -->
                                                <v-file-input
                                                v-model="imageFile"
                                                show-size
                                                counter
                                                chips
                                                accept="image/*"
                                                prepend-icon="mdi-camera"
                                                @change="uploadFile"
                                                ></v-file-input>
                                                <v-progress-circular
                                                v-if="params.loadingFile"
                                                indeterminate
                                                color="primary"
                                                ></v-progress-circular>
                                                <v-card v-if="params.loadingOk && image.length > 0">
                                                <!-- <v-img
                                                    max-height="130"
                                                    max-width="230"
                                                    :src="formNotification.image"
                                                ></v-img> -->
                                                <!-- <v-card-title class="text-h6-custom" @click="changeImage">
                                                <v-icon normal  color="blue">
                                                    mdi-file-image
                                                </v-icon>
                                                Cambiar
                                                </v-card-title> -->
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-col>



                                    <v-col cols="12" class="my-4">
                                        <v-divider></v-divider>
                                    </v-col>
                                    <v-col cols="12" md="12" class="px-1" v-if="!formProduct[3].hide">
                                        <v-card>
                                            <v-form ref="formLoadSizes">
                                                <v-row>
                                                    <v-col cols="3">
                                                        <v-select
                                                        :items="typeSize"
                                                        v-model="typeSizeSelected"
                                                        item-value="value"
                                                        item-text="description"
                                                        solo
                                                        dense
                                                        placeholder="Seleccione tipo Talle"
                                                        :rules="[rules.required]"
                                                        :disabled="action === 'edit'"
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="3">
                                                        <v-text-field
                                                            v-model="cost"
                                                            label="Precio Costo"
                                                            dense
                                                            filled
                                                            outlined
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="3">
                                                        <v-text-field
                                                            v-model="sell"
                                                            label="Precio Venta"
                                                            dense
                                                            filled
                                                            outlined
                                                            :rules="[largerThanCost]"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-btn
                                                        small
                                                        color="primary"
                                                        :disabled="!typeSizeSelected"
                                                        @click="selectSizes"
                                                        >
                                                        {{ (action === 'create' ? 'Agregar' : 'Aplicar')}}
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-data-table
                                                            :headers="headers"
                                                            :items="items"
                                                            class="elevation-1"
                                                            item-key="id"
                                                        >
                                                        <template v-slot:[`item.himage`]>
                                                            <v-img
                                                            lazy-src="https://picsum.photos/id/11/10/6"
                                                            max-height="60"
                                                            max-width="80"
                                                            :src="imageBySize"
                                                            >
                                                            </v-img>
                                                        </template>

                                                        <template v-slot:[`item.hcost`]="{ item }">
                                                            <v-text-field
                                                                v-model="item.cost"
                                                                :disabled="item.disabled"
                                                                :rules="[rules.required]"
                                                            ></v-text-field>
                                                        </template>

                                                        <template v-slot:[`item.hsell`]="{ item }">
                                                        <v-text-field
                                                                v-model="item.sell"
                                                                :disabled="item.disabled"
                                                                :rules="[itemLargerThanCost(item.cost, item.sell), rules.required]"
                                                            ></v-text-field>
                                                        </template>

                                                        <template v-slot:[`item.hcolor`]="{ item }">
                                                            <v-select
                                                            :items="colors"
                                                            v-model="item.color[0].product_color_id"
                                                            item-value="id"
                                                            item-text="description"
                                                            :disabled="item.disabled"
                                                            solo
                                                            dense
                                                            class="pt-4"
                                                            ></v-select>
                                                        </template>
                                                        <template v-slot:[`item.actions`]="{ item, index }">
                                                            <v-icon  v-if="item.disabled" @click="editItem(index)">
                                                                mdi-pencil
                                                            </v-icon>
                                                            <v-icon v-else
                                                                :disabled="validatePrice(item.cost, item.sell)"
                                                            @click="confirmItem(index)">
                                                                mdi-content-save-alert
                                                            </v-icon>
                                                        </template>
                                                        </v-data-table>

                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row >
                                    <v-col class="d-flex justify-end mr-3">
                                        <v-btn class="mr-3" small color="secondary" :disabled="!formProduct.some( e => !e.hide)" @click="closeNoSave">Salir sin guardar</v-btn>
                                        <v-btn small color="primary" :disabled="!formProduct.some( e => !e.hide)" @click="saveProduct">Guardar</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-form>
                    </v-col>

                </v-row>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import emptyImage from '@/assets/empty_image.png'

export default {
    name: 'ProductCreateEditModal',
    props: {
        action: {
            type: String,
            required: true
        },
        product: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            dialog: true,
            productImageSelected: 1,
            image:'',
            ruleImg: [ 'Formato no Aceptado'],
            params: {
                sendNow:true,
                loadingOk:false,
                loadingFile:false,
                recipientsSelectedQty:0,
                menu1:false,
                modal2:false,
                showPreview:false,
                sendToAll: false,
                labelSendNow:"Envío Inmediato",
                labelImg:"JPG o PNG. Tamaño  862 x 485 pixeles.",
            },
            imageFile: null,
            emptyImage: emptyImage,
            cost: null,
            sell: null,
            typeSize:[
                { id: 1, value: 'standard' , description: 'Standard' },
                { id: 2, value: 'letra',  description: 'Letra' },
                { id: 3, value: 'numerico', description: 'Numérico' },
                { id: 4, value: 'unico' , description: 'Unico' },
            ],
            sizeSelected: null,
            typeSizeSelected: 'standard',
            categories: [],
            brands: [],
            sizes: [],
            colors: [],
            exists: false,
            rules: {
                min: v => v.length >= 8 || 'Min 8 caracteres',
                required: v => !!v || 'Este dato es Requerido',
                requiredMultiple: v => v.length > 0 || 'Este dato es Requerido',
            },
            formProduct: [],
            headers:[
                { text:"Talle", value: "size"},
                { text:"Color", value: "hcolor"},
                { text:"Imagenes", value: "himage"},
                { text:"P. Costo", value: "hcost"},
                { text:"P. Venta", value: "hsell"},
                { text:"Acciones", value: "actions"},
            ],
            items: []
        };
    },
    computed: {
        imageBySize(){
            return () => {
                //(this.listIm)
                return this.emptyImage
            }
        },
        ifExists(){
            if (this.exists === true){
                return "Email ya existe"
            } else {
                return true
            }
        },
        clientEmail() {
            return this.formProduct.map( item => item.value );
        }
    },
    watch: {
        action(newValue){
            if(newValue === 'create'){
                this.initial()
            }
        },
        clientEmail(newValue, oldValue){
            if(newValue[2] !=  oldValue[2]){
                this.exists = false
            }
        }
    },
    created(){
        // this.getCategories()
        // this.getBrands()
        // this.getSizes()
        // this.getColors()
    },
    mounted() {
        this.initial()
        this.getCategories()
        this.getBrands()
        this.getSizes()
        this.getColors()

    },

    methods: {
        updatePrices(){
            this.items.forEach( e => {
                e.cost = this.cost
                e.sell = this.sell
            })
        },
        largerThanCost(){
            if(Number(this.cost) >= Number(this.sell)){
                return "debe ser mayor al precio de costo"
            } else {
                return true
            }
        },
        itemLargerThanCost(cost, sell){
            if(Number(cost) >= Number(sell)){
                return "debe ser mayor al precio de costo"
            } else {
                return true
            }
        },
        validatePrice(cost, sell){
            if(!cost || !sell) return true
            if(Number(cost) >= Number(sell)){
                return true
            } else {
                return false
            }
        },
        editItem(index){
            this.items[index].disabled = false
        },
        confirmItem(index){
            this.items[index].disabled = true
        },
        selectSizes(){
            if(this.action === 'create'){
                if(this.$refs.formLoadSizes.validate()){
                const sizes = this.sizes
                            .filter( e => e.type === this.typeSizeSelected)
                            .map( el => {
                                return {
                                    idSize: el.id.toString(),
                                    size: el.description,
                                    cost: this.cost,
                                    sell: this.sell,
                                    disabled: true,
                                    color: [
                                        { product_color_id: 1, product_image_id: this.productImageSelected }
                                    ]
                                }
                            })
            this.items = sizes
            }
            } else {
                this.updatePrices()
            }

        },
        getCategories(){
            this.$http.get(process.env.VUE_APP_URI + 'product_categories')
            .then( res => {
                this.categories = res.data.data
            })
            .catch( err => {
                console.log('err', err);
            })
        },
        getBrands(){
            this.$http.get(process.env.VUE_APP_URI + 'product_brands')
            .then( res => {
                this.brands = res.data.data
            })
            .catch( err => {
                console.log('err', err);
            })
        },
        getSizes(){
            this.$http.get(process.env.VUE_APP_URI + 'product_sizes')
            .then( res => {
                this.sizes = res.data.data
                if(this.action === 'edit'){
                    this.getProduct()
                }
            })
            .catch( err => {
                console.log('err', err);
            })
        },
        getProduct(){
            this.$http.get(process.env.VUE_APP_URI + `products/${this.product.id}`)
            .then( res => {
                this.item = res.data.data
                console.log('res.data item getProduct', res.data.data);
                if(this.item.image){
                    this.emptyImage = process.env.VUE_APP_URI_IMAGE + this.item.image.name
                    this.productImageSelected = this.item.image.id
                }
                this.formProduct.forEach( e => {
                    if (e.name === 'name')  e.value = this.item.name
                    if (e.name === 'description') e.value = this.item.description
                    if (e.name === 'product_category_id') e.value = this.item.product_category_id
                    if (e.name === 'product_brand_id') e.value = this.item.product_brand_id
                })
                this.typeSizeSelected = this.item.price[0].size.type
                const sizes = this.sizes
                            .filter( e => e.type === this.typeSizeSelected)
                            .map( el => {
                                return {
                                    id: this.item.price.find( element => element.size.description === el.description).id,
                                    idSize: el.id.toString(),
                                    size: el.description,
                                    cost: this.item.price.find( element => element.size.description === el.description).cost_price,
                                    sell: this.item.price.find( element => element.size.description === el.description).selling_price,
                                    disabled: true,
                                    color: [
                                        {
                                            product_color_id: this.item.price.find( element => element.size.description === el.description).stock[0].product_color_id,
                                            product_image_name: this.item.price.find( element => element.size.description === el.description).stock[0].image.name,
                                            product_image_id: this.item.price.find( element => element.size.description === el.description).stock[0].image.id,
                                        }
                                    ]
                                }
                            })
                this.items = sizes
            })
            .catch( err => {
                console.log('err', err);
            })
        },
        getColors(){
            this.$http.get(process.env.VUE_APP_URI + 'product_colors')
            .then( res => {
                this.colors = res.data.data
            })
            .catch( err => {
                console.log('err', err);
            })
        },
        initial(){
            this.formProduct = [
                { id: 0, label: 'Nombre', name: 'name', value: null, hide: false },
                { id: 1, label: 'Descripcion', name: 'description', value: null, hide: false },
                { id: 2, label: 'Categoria', name: 'product_category_id', value: null, hide: false, valueDescription: null },
                { id: 3, label: 'Marca', name: 'product_brand_id', value: null, hide: false, valueDescription: null },
            ]
        },
        saveProduct(){
            this.exists = ""
            if(this.$refs.form.validate()){

                const data = this.preparateData()

                if( this.action === 'create'){
                    this.$http.post(
                    process.env.VUE_APP_URI + 'products', data )
                    .then( () => {
                        this.$emit('close', this.product )
                    })
                    .catch( err => {
                        if(err.response.status === 422){
                            const data = err.response.data
                            if(data.message === 'errorValidation'){
                                if(data.data.email) {
                                    this.exists = true
                                }
                            }
                        }
                    })
                } else {
                    this.$http.put(
                    process.env.VUE_APP_URI + `products/${this.product.id}`, data )
                    .then( () => {
                        this.$emit('close', this.product, data.prices, this.formProduct[0].value)
                    })
                    .catch( err => {
                        alert('Se produjo un error')
                        console.log(err)
                    })
                }

            }
        },
        preparateData(){
             this.items.forEach( el => {
                    el.color[0].product_image_id = this.productImageSelected
                    el.color[0].product_image_name = this.emptyImage
            })
            const data = this.formProduct
                .map( el => {
                    const obj = {};
                    obj[el.name] = el.value
                    return obj
                })
                .reduce((acc, cur) => {
                    const [key, value] = Object.entries(cur)[0];
                    acc[key] = value;
                    return acc;
                }, {})
                data.prices = this.items
                data.product_image_id = this.productImageSelected
            return data
        },
        closeNoSave(){
            this.$emit('close')
        },
        uploadFile(){

            if (this.imageFile) {
                if (this.imageFile.type === 'image/png' || this.imageFile.type === 'image/jpeg'){
                let fileName
                if (this.imageFile.type === 'image/png') {
                    fileName = 'image1' + '.png'
                }
                if (this.imageFile.type === 'image/jpeg') {
                    fileName = 'image1' + '.jpg'
                }

                this.params.loadingFile = true
                let formData = new FormData();
                formData.append("image", this.imageFile, fileName);

                this.$http
                    .post(process.env.VUE_APP_URI + 'images', formData)
                    .then(res => {
                        this.params.loadingFile = false;
                        this.params.loadingOk = true;
                        this.emptyImage = process.env.VUE_APP_URI_IMAGE + res.data.data.name
                        this.productImageSelected = res.data.data.id
                    })
                    .catch(error => {
                        console.log({ error });
                    });
            } else {
                this.archivo = null;
                this.params.labelImg = 'error';
            }
            }
        },
    },
};
</script>

<style scoped>

</style>
