<template>
    <div class="ma-1">
        <v-text-field
        outlined
        v-model="formLogin.email"
        label="Email"
        >
        </v-text-field>
        <v-text-field
        outlined
        v-model="formLogin.password"
        type="password"
        label="Password"
        >
        </v-text-field>
        <v-btn @click="tryLogin" block rounded dark>
            Ingresar
        </v-btn>
    </div>
</template>
<script>
export default {
    name: "LoginForm",
    data() {
        return {
            formLogin: {
                email: null,
                password: null,
            }
        };
    },
    methods: {
        async tryLogin() {
            try {
                const response = await this.$store.dispatch('login', this.formLogin)
                if(response.status === 'error'){
                    console.log('error found...');
                } else {
                    this.$router.push({name: 'home'})
                }
            } catch (error) {
                console.log(error)
            }
        },
    }
};
 </script>

 <style scoped>
    html {
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

html::-webkit-scrollbar {
  width: 0;
  height: 0;
}
 </style>
