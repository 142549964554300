import Vue from "vue";
import Vuex from "vuex";
import { authentication } from './modules/authentication.module';
import { title } from './modules/title.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    authentication,
    title
  },
});
