<template>
    <SalesComponent />
</template>
<script>
    import SalesComponent from "@/components/sales/SalesComponent";

    export default {
    name: "SalesView",

    components: {
        SalesComponent,
    },
    };
  </script>
